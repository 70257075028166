import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import Icon from 'components/common/Icon';
import { __, translatedProperty } from 'utils/translation';
import copy from 'clipboard-copy';
import './index.scss';
import { getSearchParams } from 'utils/url';
import arrow from 'images/arrow.png';


const Review = ({
    items
}) => {

    const text = items.map(item => translatedProperty(item, "text"))
        .reduce((acc, txt) => acc += !acc ? txt : ", " + txt, "");

    const [reviewField, setReviewField] = useState(false);
    const [reviewCopied, setReviewCopied] = useState(false);
    const [wasCopied, setWasCopied] = useState(false);

    const { messengers } = useSelector((state) => state.config.data);

    let instagram = messengers.filter(e => e.title === "Instagram")[0]?.value?.toLowerCase();
    const instagramUrl = "instagram.com/"
    if (instagram && instagram.includes(instagramUrl)) {
        instagram = instagram.substring(instagram.indexOf(instagramUrl) + instagramUrl.length)
    }
    const time = new Date().toLocaleString().replace(",", "");
    const params = getSearchParams(window.location.search, ["pwa", "demo"]);
    let paramsValue = ""
    for (var propName in params) {
        paramsValue += `${propName} ${params[propName]}`.trim() + ", ";
    }
    const review = `${__("Мой заказ: ")}${text}, ${__("был заказан в ")} @${instagram}  ${paramsValue} ${__("в мобильном приложении: ")} @sweety_pwa ${__(" У меня осталось вот такое впечатление: ")} `;

    const onCopy = () => {
        copy(review);
        setWasCopied(true);
        setReviewCopied(true);
        setTimeout(() => setReviewCopied(false), 2000);
    };

    return (
        <React.Fragment>

            {instagram && (
                <>
                    {!reviewField && (
                        <Button className="review-botton" noStyled
                            onClick={() => setReviewField(true)} >
                            {__("Добавить отзыв в Инстаграм")}

                            <div className="review-botton__sub-cta">{__("чтобы получить комплимент от нас")}</div>
                        </Button>)
                    }
                    {reviewField && (
                        <div className="review-field">
                            <div className="review-field__title">{__("Спасибо вам за то, что вы согласились оставить отзыв о")}&nbsp;{translatedProperty(items[0], "text")}</div>
                            <div className="review-field__subtitle">{__("ШАГ 1: Скопируйте эту подпись, чтобы добавить в новый пост для вашего Инстаграм")}<br />
                                <span>{review}</span>
                            </div>

                            <div className="review-botton-box">
                                <Button className="review-botton" noStyled
                                    onClick={onCopy} >
                                    {reviewCopied ? __("Текст скопирован") :
                                        <input className='review-botton__linkrenderer' value={review} readOnly={true} />}
                                </Button>
                                {!wasCopied ?
                                    <div className="review-botton-box__arrow" style={{ backgroundImage: `URL(${arrow})` }}></div> : null}
                            </div>
                            {!wasCopied ?
                                <div className="review-field__under-botton__noactive">{__("Нажмите, чтобы скопировать эту подпись")}</div>
                                :
                                <div className="review-field__under-botton">{__("Нажмите, чтобы скопировать эту подпись")}</div>
                            }

                            <div className="review-field__subtitle">{__("ШАГ 2: Создайте новый пост, сделайте фото, добавьте подпись... (вставьте текст, который вы скопировали)")}<br />
                            </div>

                            {!wasCopied ?
                                <><Button className="review-botton__noactive" noStyled
                                    onClick={() => { }} >
                                    {__("Создать пост в Инстаграм")}
                                </Button>
                                    <div className="review-field__under-botton">{__("Сначала скопируйте текст для подписи")}</div>
                                </>
                                :
                                <>
                                    <Button className="review-botton" noStyled
                                        onClick={() => window.open('https://www.instagram.com', '_blank')} >
                                        {__("Создать пост в Инстаграм")}
                                    </Button>
                                    <div className="review-field__under-botton">{__("Нажмите, чтобы открыть ваш Инстаграм")}</div>
                                </>
                            }

                            <div className="review-field__subtitle">{__("ШАГ 3: Покажите нашему официанту ваш пост в Инстаграм и получите свой комплимент")}<br />
                            </div>
                            <br />
                            <div className="settings-video-link-box">
                                <a href="https://youtu.be/sSCjWqGgnRw" target="_blank">
                                    <div className="settings-video-link-box__flexbox" style={{ marginLeft: 12 }}>
                                        <Icon className="settings-video-link-box__flexbox__icon" type="youtube" />
                                        <div className="settings-video-link-box__flexbox__text">{__("Нажмите, чтобы посмотреть инструкцию в YouTube")}</div>
                                    </div>
                                </a>
                            </div>
                            <br />
                        </div>)
                    }

                </>
            )}

        </React.Fragment >
    );
};

Review.propTypes = {
    text: PropTypes.string,
};

Review.defaultProps = {
    text: ''
};

export default Review;