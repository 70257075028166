import React, { useState } from 'react';
import Icon from 'components/common/Icon';

import './index.scss';

const MinuteCounter = ({date}) => {
 const now = new Date 
 let msNow = Date.parse(now.toISOString());
 let msDate = Date.parse(date);
 const msAgo =  Number(msNow) - Number(msDate) ;
 const minuteAgo = msAgo / 60000;
 const minAgo = parseFloat(minuteAgo.toFixed(0));

    return (
        <div>{minAgo}</div>
    );
};

export default MinuteCounter;