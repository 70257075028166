import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';



const Avatar = ({
    image,
    text,
    link
}) => {
    return (
        <>
            {image && <div className="avatar-maps"  onClick={() => window.open(link, "_blank")} style={{ backgroundImage: `URL(${image})` }} />}
            <h1 className="main-page__page1__h3" >{text}</h1>
        </>
    );
};

Avatar.propTypes = {
    image: PropTypes.string,
    text: PropTypes.string
};

Avatar.defaultProps = {
    image: undefined,
    text: ''
};

export default Avatar;
