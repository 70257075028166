import { takeEvery, put, call, all } from 'redux-saga/effects';
import { openDB } from 'idb';
import { uuid } from 'uuidv4';

import {
  CONFIG_LOAD,
  CONFIG_LOAD_ONLINE,
  LOADING_ERROR,
  SET_DATA,
  PRELOAD_DATA,
  CACHE_DATA,
  CACHE_PLAYLISTS,
  PRELOAD_PLAYLISTS,
  CACHE_KEY_OWNER
} from 'constants/actions';

import API from 'utils/api';
import fileReader from 'utils/fileReader';
//import loadingData from 'mocks/data.json';
console.log("configPwa sagas Started")
const DATABASE_NAME = "Maps.Menu";
const DATABASE_VERSION = 1;
const PROFILE_STORE = "profile";
const CONTENT_STORE = "content";
const LOADING_ATTEMPTS = 10;

function dbPromise() {
  return openDB(DATABASE_NAME, DATABASE_VERSION, {
    upgrade(db, oldVersion, newVersion, transaction) {
      if (oldVersion < newVersion) {
        try {
          db.createObjectStore(PROFILE_STORE);
        } catch {
        }

        try {
          db.createObjectStore(CONTENT_STORE);
        } catch {
        }
      }
    }
  });
}

function dbPut(db, storeName, value, query) {
  if (!db) {
    return Promise.resolve(null);
  }

  try {
    return db.put(storeName, value, query);
  } catch (error) {
    console.warn(error);
    return Promise.resolve(null);
  }
}

function dbGet(db, storeName, query) {
  if (!db) {
    return Promise.resolve(null);
  }

  try {
    return db.get(storeName, query);
  } catch (error) {
    console.warn(error);
    return Promise.resolve(null);
  }
}

function dbGetAllKeys(db, storeName) {
  if (!db) {
    return Promise.resolve([]);
  }

  try {
    return db.getAllKeys(storeName);
  } catch (error) {
    console.warn(error);
    return Promise.resolve([]);
  }
}

function contentKey(profile, key) {
  return `${profile}:${key}`;
}

function* processLoadingData(loadingData, profile) {
  let db = null;
  try {
    db = yield call(dbPromise, {});
  } catch {
  }

  const {
    themes,
    buttonColors,
    backgrounds,
    config,
    account,
    ...data
  } = loadingData;

  if (loadingData.subscriptions && loadingData.subscriptions.length > 0) {
    let ownerId = call(dbGet, db, PROFILE_STORE, CACHE_KEY_OWNER);
    if (!ownerId) {
      ownerId = uuid();
      call(dbPut, db, CONTENT_STORE, ownerId, CACHE_KEY_OWNER);
    }
  }

  let isSubscriber = false;
  if (data?.settings?.subscriberPeriod) {
    let subscribedUntil = data.settings.subscriberPeriod.indexOf("-") === -1 ? "" :
      data.settings.subscriberPeriod.substr(data.settings.subscriberPeriod.indexOf("-") + 1);
    isSubscriber = !subscribedUntil || (Date.parse(subscribedUntil) + 1 >= new Date());
  }

  let keys = yield call(dbGetAllKeys, db, CONTENT_STORE);

  const tracks = data.catalogItems.filter(c => c.audio && c.audio.startsWith("http")).map(c => c.audio);
  const cachedTracks = tracks.filter(t => keys.includes(contentKey(profile, t)));
  const trackCaches = yield all(cachedTracks.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

  const paidTracks = !isSubscriber ? [] :
    data.catalogItems.filter(c => c.audioPaid && c.audioPaid.startsWith("http")).map(c => c.audioPaid);
  const paidCachedTracks = paidTracks.filter(t => keys.includes(contentKey(profile, t)));
  const paidTrackCaches = yield all(paidCachedTracks.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

  // кэшируем все каталоги
    //   catalogItems
    const cimages = data.catalogItems.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedCImages = cimages.filter(t => keys.includes(contentKey(profile, t)));
    const cimageCaches = yield all(cachedCImages.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

  //   catalog2Items
    const c2images = data.catalog2Items.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedC2Images = c2images.filter(t => keys.includes(contentKey(profile, t)));
    const c2imageCaches = yield all(cachedC2Images.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

  //   catalog3Items
    const c3images = data.catalog3Items.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedC3Images = c3images.filter(t => keys.includes(contentKey(profile, t)));
    const c3imageCaches = yield all(cachedC3Images.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

  //   catalog4Items
    const c4images = data.catalog4Items.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedC4Images = c4images.filter(t => keys.includes(contentKey(profile, t)));
    const c4imageCaches = yield all(cachedC4Images.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

  //   catalog5Items
    const c5images = data.catalog5Items.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedC5Images = c5images.filter(t => keys.includes(contentKey(profile, t)));
    const c5imageCaches = yield all(cachedC5Images.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

  //   catalogBottles
    const cbimages = data.catalogBottles.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedCbImages = cbimages.filter(t => keys.includes(contentKey(profile, t)));
    const cbimageCaches = yield all(cachedCbImages.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

  //   catalogCakes
    const ckimages = data.catalogCakes.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedCkImages = ckimages.filter(t => keys.includes(contentKey(profile, t)));
    const ckimageCaches = yield all(cachedCkImages.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

    //   catalogCigarettas
    const ccimages = data.catalogCigarettas.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedCcImages = ccimages.filter(t => keys.includes(contentKey(profile, t)));
    const ccimageCaches = yield all(cachedCcImages.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

      // catalogDishes
    const cdimages = data.catalogDishes.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedCdImages = cdimages.filter(t => keys.includes(contentKey(profile, t)));
    const cdimageCaches = yield all(cachedCdImages.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

    //   catalogDrinks
    const crimages = data.catalogDrinks.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedCrImages = crimages.filter(t => keys.includes(contentKey(profile, t)));
    const crimageCaches = yield all(cachedCrImages.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

  //   catalogOffers
    const coimages = data.catalogOffers.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedCoImages = coimages.filter(t => keys.includes(contentKey(profile, t)));
    const coimageCaches = yield all(cachedCoImages.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

  //   catalogSnacks
    const csimages = data.catalogSnacks.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedCsImages = csimages.filter(t => keys.includes(contentKey(profile, t)));
    const csimageCaches = yield all(cachedCsImages.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

  //   catalogSoftDrinks
    const caimages = data.catalogSoftDrinks.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedCaImages = caimages.filter(t => keys.includes(contentKey(profile, t)));
    const caimageCaches = yield all(cachedCaImages.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

  //   catalogTables
    const ctimages = data.catalogTables.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedCtImages = ctimages.filter(t => keys.includes(contentKey(profile, t)));
    const ctimageCaches = yield all(cachedCtImages.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));


    
    // кэшируем все сторис
  //   stories
    const simages = data.stories.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedSImages = simages.filter(t => keys.includes(contentKey(profile, t)));
    const simageCaches = yield all(cachedSImages.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

  //   stories2Items
    const s2images = data.stories2Items.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedS2Images = s2images.filter(t => keys.includes(contentKey(profile, t)));
    const s2imageCaches = yield all(cachedS2Images.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

  //   stories3Items
    const s3images = data.stories3Items.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedS3Images = s3images.filter(t => keys.includes(contentKey(profile, t)));
    const s3imageCaches = yield all(cachedS3Images.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

  //   stories4Items
    const s4images = data.stories4Items.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedS4Images = s4images.filter(t => keys.includes(contentKey(profile, t)));
    const s4imageCaches = yield all(cachedS4Images.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

  //   stories5Items
    const s5images = data.stories5Items.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedS5Images = s5images.filter(t => keys.includes(contentKey(profile, t)));
    const s5imageCaches = yield all(cachedS5Images.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

  //   storiesBottles
    const sbimages = data.storiesBottles.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedSbImages = sbimages.filter(t => keys.includes(contentKey(profile, t)));
    const sbimageCaches = yield all(cachedSbImages.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

  //   storiesCakes
    const skimages = data.storiesCakes.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedSkImages = skimages.filter(t => keys.includes(contentKey(profile, t)));
    const skimageCaches = yield all(cachedSkImages.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

  //   storiesCigarettas
    const scimages = data.storiesCigarettas.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedScImages = scimages.filter(t => keys.includes(contentKey(profile, t)));
    const scimageCaches = yield all(cachedScImages.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

  //   storiesDishes
    const sdimages = data.storiesDishes.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedSdImages = sdimages.filter(t => keys.includes(contentKey(profile, t)));
    const sdimageCaches = yield all(cachedSdImages.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

  //   storiesDrinks
    const srimages = data.storiesDrinks.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedSrImages = srimages.filter(t => keys.includes(contentKey(profile, t)));
    const srimageCaches = yield all(cachedSrImages.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

  //   storiesOffers
    const soimages = data.storiesOffers.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedSoImages = soimages.filter(t => keys.includes(contentKey(profile, t)));
    const soimageCaches = yield all(cachedSoImages.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

  //   storiesSnacks
    const ssimages = data.storiesSnacks.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedSsImages = ssimages.filter(t => keys.includes(contentKey(profile, t)));
    const ssimageCaches = yield all(cachedSsImages.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

  //   storiesSoftDrinks
    const saimages = data.storiesSoftDrinks.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedSaImages = saimages.filter(t => keys.includes(contentKey(profile, t)));
    const saimageCaches = yield all(cachedSaImages.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

  //   storiesTables
    const stimages = data.storiesTables.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedStImages = stimages.filter(t => keys.includes(contentKey(profile, t)));
    const stimageCaches = yield all(cachedStImages.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

  
  // кэшируем блокс
    const bimages = data.blocks.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedBImages = bimages.filter(t => keys.includes(contentKey(profile, t)));
    const bimageCaches = yield all(cachedBImages.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

  
  // кэшируем аватарс
    const aimages = data.avatars.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedAImages = aimages.filter(t => keys.includes(contentKey(profile, t)));
    const aimageCaches = yield all(cachedAImages.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

  
  // кэшируем google maps
    const gimages = data.googleMaps.filter(c => c.image && c.image.startsWith("http")).map(c => c.image);
    const cachedGImages = gimages.filter(t => keys.includes(contentKey(profile, t)));
    const gimageCaches = yield all(cachedGImages.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));

  cachedTracks.forEach((a, i) => {
    let cachedBlob = trackCaches[i];
    if (cachedBlob) {
      data.catalogItems.filter(c => c.audio === a).forEach(c => {
        c.audioCache = cachedBlob;
      });
    }
  });

  paidCachedTracks.forEach((a, i) => {
    let cachedBlob = paidTrackCaches[i];
    if (cachedBlob) {
      data.catalogItems.filter(c => c.audioPaid === a).forEach(c => {
        c.audioPaidCache = cachedBlob;
      });
    }
  });

  cachedCImages.forEach((a, i) => {
    let cachedBlob = cimageCaches[i];
    if (cachedBlob) {
      data.catalogItems.filter(c => c.image === a).forEach(c => {
        c.image = cachedBlob;
      });
    }
  });

  
//   catalog2Items
  cachedC2Images.forEach((a, i) => {
    let cachedBlob = c2imageCaches[i];
    if (cachedBlob) {
      data.catalog2Items.filter(c2 => c2.image === a).forEach(c2 => {
        c2.image = cachedBlob;
      });
    }
  });

  
//   catalog3Items
  cachedC3Images.forEach((a, i) => {
    let cachedBlob = c3imageCaches[i];
    if (cachedBlob) {
      data.catalog3Items.filter(c3 => c3.image === a).forEach(c3 => {
        c3.image = cachedBlob;
      });
    }
  });

//   catalog4Items
  cachedC4Images.forEach((a, i) => {
    let cachedBlob = c4imageCaches[i];
    if (cachedBlob) {
      data.catalog4Items.filter(c4 => c4.image === a).forEach(c4 => {
        c4.image = cachedBlob;
      });
    }
  });

//   catalog5Items
  cachedC5Images.forEach((a, i) => {
    let cachedBlob = c5imageCaches[i];
    if (cachedBlob) {
      data.catalog5Items.filter(c5 => c5.image === a).forEach(c5 => {
        c5.image = cachedBlob;
      });
    }
  });
  
//   catalogBottles
  cachedCbImages.forEach((a, i) => {
    let cachedBlob = cbimageCaches[i];
    if (cachedBlob) {
      data.catalogBottles.filter(cb => cb.image === a).forEach(cb => {
        cb.image = cachedBlob;
      });
    }
  });

//   catalogCakes
  cachedCkImages.forEach((a, i) => {
    let cachedBlob = ckimageCaches[i];
    if (cachedBlob) {
      data.catalogCakes.filter(ck => ck.image === a).forEach(ck => {
        ck.image = cachedBlob;
      });
    }
  });

//   catalogCigarettas
  cachedCcImages.forEach((a, i) => {
    let cachedBlob = ccimageCaches[i];
    if (cachedBlob) {
      data.catalogCigarettas.filter(cc => cc.image === a).forEach(cc => {
        cc.image = cachedBlob;
      });
    }
  });

//   catalogDishes
  cachedCdImages.forEach((a, i) => {
    let cachedBlob = cdimageCaches[i];
    if (cachedBlob) {
      data.catalogDishes.filter(cd => cd.image === a).forEach(cd => {
        cd.image = cachedBlob;
      });
    }
  });

//   catalogDrinks
  cachedCrImages.forEach((a, i) => {
    let cachedBlob = crimageCaches[i];
    if (cachedBlob) {
      data.catalogDrinks.filter(cr => cr.image === a).forEach(cr => {
        cr.image = cachedBlob;
      });
    }
  });

//   catalogOffers
  cachedCoImages.forEach((a, i) => {
    let cachedBlob = coimageCaches[i];
    if (cachedBlob) {
      data.catalogOffers.filter(co => co.image === a).forEach(co => {
        co.image = cachedBlob;
      });
    }
  });

//   catalogSnacks
  cachedCsImages.forEach((a, i) => {
    let cachedBlob = csimageCaches[i];
    if (cachedBlob) {
      data.catalogSnacks.filter(cs => cs.image === a).forEach(cs => {
        cs.image = cachedBlob;
      });
    }
  });

//   catalogSoftDrinks
  cachedCaImages.forEach((a, i) => {
    let cachedBlob = caimageCaches[i];
    if (cachedBlob) {
      data.catalogSoftDrinks.filter(ca => ca.image === a).forEach(ca => {
        ca.image = cachedBlob;
      });
    }
  });

//   catalogTables
  cachedCtImages.forEach((a, i) => {
    let cachedBlob = ctimageCaches[i];
    if (cachedBlob) {
      data.catalogTables.filter(ct => ct.image === a).forEach(ct => {
        ct.image = cachedBlob;
      });
    }
  });

  

  // кэшируем все сторис
//   stories
  cachedSImages.forEach((a, i) => {
    let cachedBlob = simageCaches[i];
    if (cachedBlob) {
      data.stories.filter(c => c.image === a).forEach(c => {
        c.image = cachedBlob;
      });
    }
  });

//   stories2Items
  cachedS2Images.forEach((a, i) => {
    let cachedBlob = s2imageCaches[i];
    if (cachedBlob) {
      data.stories2Items.filter(c => c.image === a).forEach(c => {
        c.image = cachedBlob;
      });
    }
  });

//   stories3Items
  cachedS3Images.forEach((a, i) => {
    let cachedBlob = s3imageCaches[i];
    if (cachedBlob) {
      data.stories3Items.filter(c => c.image === a).forEach(c => {
        c.image = cachedBlob;
      });
    }
  });

//   stories4Items
  cachedS4Images.forEach((a, i) => {
    let cachedBlob = s4imageCaches[i];
    if (cachedBlob) {
      data.stories4Items.filter(c => c.image === a).forEach(c => {
        c.image = cachedBlob;
      });
    }
  });

//   stories5Items
  cachedS5Images.forEach((a, i) => {
    let cachedBlob = s5imageCaches[i];
    if (cachedBlob) {
      data.stories5Items.filter(c => c.image === a).forEach(c => {
        c.image = cachedBlob;
      });
    }
  });

//   storiesBottles
  cachedSbImages.forEach((a, i) => {
    let cachedBlob = sbimageCaches[i];
    if (cachedBlob) {
      data.storiesBottles.filter(c => c.image === a).forEach(c => {
        c.image = cachedBlob;
      });
    }
  });

//   storiesCakes
  cachedSkImages.forEach((a, i) => {
    let cachedBlob = skimageCaches[i];
    if (cachedBlob) {
      data.storiesCakes.filter(c => c.image === a).forEach(c => {
        c.image = cachedBlob;
      });
    }
  });

//   storiesCigarettas
  cachedScImages.forEach((a, i) => {
    let cachedBlob = scimageCaches[i];
    if (cachedBlob) {
      data.storiesCigarettas.filter(c => c.image === a).forEach(c => {
        c.image = cachedBlob;
      });
    }
  });

//   storiesDishes
  cachedSdImages.forEach((a, i) => {
    let cachedBlob = sdimageCaches[i];
    if (cachedBlob) {
      data.storiesDishes.filter(c => c.image === a).forEach(c => {
        c.image = cachedBlob;
      });
    }
  });

//   storiesDrinks
  cachedSrImages.forEach((a, i) => {
    let cachedBlob = srimageCaches[i];
    if (cachedBlob) {
      data.storiesDrinks.filter(c => c.image === a).forEach(c => {
        c.image = cachedBlob;
      });
    }
  });

//   storiesOffers
  cachedSoImages.forEach((a, i) => {
    let cachedBlob = soimageCaches[i];
    if (cachedBlob) {
      data.storiesOffers.filter(c => c.image === a).forEach(c => {
        c.image = cachedBlob;
      });
    }
  });

//   storiesSnacks
  cachedSsImages.forEach((a, i) => {
    let cachedBlob = ssimageCaches[i];
    if (cachedBlob) {
      data.storiesSnacks.filter(c => c.image === a).forEach(c => {
        c.image = cachedBlob;
      });
    }
  });

//   storiesSoftDrinks
  cachedSaImages.forEach((a, i) => {
    let cachedBlob = saimageCaches[i];
    if (cachedBlob) {
      data.storiesSoftDrinks.filter(c => c.image === a).forEach(c => {
        c.image = cachedBlob;
      });
    }
  });

//   storiesTables
  cachedStImages.forEach((a, i) => {
    let cachedBlob = stimageCaches[i];
    if (cachedBlob) {
      data.storiesTables.filter(c => c.image === a).forEach(c => {
        c.image = cachedBlob;
      });
    }
  });


// кэшируем блокс
  cachedBImages.forEach((a, i) => {
    let cachedBlob = bimageCaches[i];
    if (cachedBlob) {
      data.blocks.filter(c => c.image === a).forEach(c => {
        c.image = cachedBlob;
      });
    }
  });


// кэшируем аватарс
  cachedAImages.forEach((a, i) => {
    let cachedBlob = aimageCaches[i];
    if (cachedBlob) {
      data.avatars.filter(c => c.image === a).forEach(c => {
        c.image = cachedBlob;
      });
    }
  });


// кэшируем google maps
  cachedGImages.forEach((a, i) => {
    let cachedBlob = gimageCaches[i];
    if (cachedBlob) {
      data.googleMaps.filter(c => c.image === a).forEach(c => {
        c.image = cachedBlob;
      });
    }
  });

  data.catalogItems.forEach(ci => {
    if (ci.text?.startsWith("https://docs.google.com")) {
      ci.playlistUrl = ci.text;
      ci.text = null;
      ci.textEn = null;
      ci.textEs = null;
      ci.textRu = null;
      ci.textIt = null;
      ci.textDe = null;
      ci.textFr = null;
      ci.textZh = null;
    }
  });
  const playlists = data.catalogItems.filter(ci => ci.playlistUrl).map(ci => ci.playlistUrl);
  const cachedPlaylists = playlists.filter(t => keys.includes(contentKey(profile, t)));
  const playlistCaches = yield all(cachedPlaylists.map(a => call(dbGet, db, CONTENT_STORE, contentKey(profile, a))));
  data.catalogItems.filter(ci => ci.playlistUrl).forEach(ci => {
    const cachedIndex = cachedPlaylists.indexOf(ci.playlistUrl);
    ci.playlist = cachedIndex > -1 ? playlistCaches[cachedIndex] : [];
    ci.audio = ci.playlist[0]?.audio;
    ci.text = ci.playlist[0]?.text;
    // TODO. Find appropriate track
  });

  const preloadingAvatars = [];
  if (data.avatar && keys.includes(contentKey(profile, data.avatar))) {
    data.avatar = yield call(dbGet, db, CONTENT_STORE, contentKey(profile, data.avatar));
  } else if (data.avatar) {
    preloadingAvatars.push({ url: data.avatar, type: "image" });
  }
  if (data.avatarPreview && keys.includes(contentKey(profile, data.avatarPreview))) {
    data.avatarPreview = yield call(dbGet, db, CONTENT_STORE, contentKey(profile, data.avatarPreview));
  } else if (data.avatarPreview) {
    preloadingAvatars.push({ url: data.avatarPreview, type: "image" });
  }

  yield put({
    type: SET_DATA,
    themes,
    buttonColors,
    backgrounds,
    config,
    account,
    data,
    isSubscriber
  });

  const preloading = [...new Set(
    tracks.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "audio" }})
      .concat(paidTracks.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "audio" }}))
      .concat(cimages.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(c2images.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(c3images.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(c4images.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(c5images.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(cbimages.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(ckimages.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(ccimages.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(cdimages.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(crimages.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(coimages.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(csimages.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(caimages.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(ctimages.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))      
      .concat(simages.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(s2images.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(s3images.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(s4images.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(s5images.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(sbimages.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(skimages.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(scimages.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(sdimages.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(srimages.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(soimages.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(ssimages.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(saimages.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(stimages.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(bimages.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(aimages.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(gimages.filter(t => !keys.includes(contentKey(profile, t))).map(url => { return { url, type: "image" }}))
      .concat(preloadingAvatars))];
  if (preloading.length > 0) {
    yield put({
      type: PRELOAD_DATA,
      profile,
      contentUrls: preloading
    });
  }

  const preloadingPlaylists = [...new Set(
    playlists.filter(t => !keys.includes(contentKey(profile, t))))];
  if (preloadingPlaylists.length > 0) {
    yield put({
      type: PRELOAD_PLAYLISTS,
      profile,
      playlists: preloadingPlaylists
    });
  }
}

function* loadConfig({ profile }) {
  try {
    let loadingData = null;
    let db = null;
    try
    {
      db = yield call(dbPromise, {});
    } catch { }

    loadingData = yield call(dbGet, db, PROFILE_STORE, profile);
    if (!loadingData) {
      yield loadConfigOnline({ profile });

      loadingData = yield call(dbGet, db, PROFILE_STORE, profile);

      if (!loadingData) {
        yield put({ type: LOADING_ERROR, error: "No data" });
      }
    } else {
      yield call(processLoadingData, loadingData, profile);

      yield put({ type: CONFIG_LOAD_ONLINE, profile });
    }
  }
  catch (error) {
    yield put({ type: LOADING_ERROR, error });
  }
}

function* loadConfigOnline({ profile }) {
  try {
    let loadingData = null;
    let db = null;
    try {
      db = yield call(dbPromise, {});
    } catch { }
    const loadingDataOffline = yield call(dbGet, db, PROFILE_STORE, profile);

    let ownerId = yield call(dbGet, db, PROFILE_STORE, CACHE_KEY_OWNER);
    if (ownerId) {
      yield call(API.updateOwner, ownerId);
    }
  
    let loading = 0;
    while (!loadingData && loading < LOADING_ATTEMPTS) {
      loadingData = yield call(API.getData, {});
      if (loadingData != null) {
        if (loadingDataOffline && JSON.stringify(loadingDataOffline) === JSON.stringify(loadingData)) {
          return;
        }
        yield call(dbPut, db, PROFILE_STORE, loadingData, profile);
      }
      loading++;
    }
    if (loadingData) {
      yield call(processLoadingData, loadingData, profile);
    }
  }
  catch (error) {
    yield put({ type: LOADING_ERROR, error });
  }
}

function* preloadByUrl(url) {
  
  const blob = yield call(API.toDataUrl, url);
  if (!blob) {
    return null;
  }
  try {
    return yield call(fileReader, blob);
  } catch {
    return null;
  }
}

function* preloadData({ profile, contentUrls}) {
  const cachingBase64 = yield all(contentUrls.map(cu => call(preloadByUrl, cu.url)));
  const db = yield call(dbPromise, {});

  const cached = {};
  const putCalls = [];
  contentUrls.forEach((cu, i) => {
    const cachedBase64 = cachingBase64[i];
    if (cachedBase64) {
      cached[cu.url] = cachedBase64;
      putCalls.push(call(dbPut, db, CONTENT_STORE, 
        cu.type == "audio" ? cachedBase64.replace("data:video", "data:audio") : cachedBase64,
        contentKey(profile, cu.url)));
    }
  });
  yield all(putCalls);

  if (Object.keys(cached).length > 0) {
    yield put({ type: CACHE_DATA, cached });
  }
}

function* preloadPlaylists({ profile, playlists}) {
  const db = yield call(dbPromise, {});

  const googleSheets = yield all(playlists.map(l => call(API.getGoogleSpreadSheet, l)));
  const contentList = googleSheets.map((rows, i) => {
    const items = rows.filter(row => row.audio).map(row => {
      return {
        guid: uuid(),
        number: row.number?.trim(),
        timeFrom: row.timeFrom?.trim(),
        timeTo: row.timeTo?.trim(),
        text: row.text?.trim(),
        audio: row.audio?.trim(),
        audioPaid: row.audioPaid?.trim(),
      };
    });
    return {
      url: playlists[i],
      items
    };
  });
  yield all(contentList.map(e => call(dbPut, db, CONTENT_STORE, 
    e.items,
    contentKey(profile, e.url))));

  const cached = {};
  contentList.forEach(e => {
    cached[e.url] = e.items
  });

  if (Object.keys(cached).length > 0) {
    yield put({ type: CACHE_PLAYLISTS, cached });
  }

  const keys = yield call(dbGetAllKeys, db, CONTENT_STORE);
  const preloading = [];
  contentList.forEach(l => {
    l.items.forEach(e => {
      if (e.audio && !keys.includes(contentKey(profile, e.audio))) {
        preloading.push(e.audio);
      }
    });
  });

  /*if (preloading.length > 0) {
    yield put({
      type: PRELOAD_DATA,
      profile,
      contentUrls: [...new Set(preloading)].map(url => { return { url, type: "audio" } })
    });
  }*/
}

export default function* configPwa() {
  yield takeEvery(CONFIG_LOAD, loadConfig);
  yield takeEvery(CONFIG_LOAD_ONLINE, loadConfigOnline);
  yield takeEvery(PRELOAD_DATA, preloadData);
  yield takeEvery(PRELOAD_PLAYLISTS, preloadPlaylists);
}
