import React from 'react';
import { __ } from 'utils/translation';
import Input from 'components/common/Input';
import Button from 'components/common/Button';
import Icon from 'components/common/Icon';

import './index.scss';


const SearchComponent = ({ 
    search,
    placeholder,
    onSearch
}) => {
    const updateSearch = (value) => {
        onSearch(value);
    };

    return (
        <React.Fragment>
            <div className="search-component">
            <Icon className="search-component__icon" type="search" />
                <Input className="search-component__input"
                    value={search}
                    name="fieldTextAltCaltalog"
                    type="text"
                    placeholder={placeholder}
                    onChange={(value) => updateSearch(value)} 
                />
                
            </div>
            <div className="search-component__field-header">{__("Для поиска введите минимум 3 символа")}</div>
        </React.Fragment>
    );
};

export default SearchComponent;