export const LOADING_ERROR = 'LOADING_ERROR';

export const LANG_SETTINGS = 'LANG_SETTINGS';

export const CONFIG_LOAD = 'CONFIG_LOAD';
export const CONFIG_LOAD_ONLINE = 'CONFIG_LOAD_ONLINE';
export const SET_DATA = 'SET_DATA';
export const PRELOAD_DATA = 'PRELOAD_DATA';
export const CACHE_DATA = 'CACHE_DATA';
export const CACHE_PLAYLISTS = 'CACHE_PLAYLISTS';
export const CACHE_KEY_OWNER = 'owner';
export const PRELOAD_PLAYLISTS = 'PRELOAD_PLAYLISTS';

export const CHANGE_THEME = 'CHANGE_THEME';
export const CHANGE_BACKGROUND = 'CHANGE_BACKGROUND';
export const CHANGE_BUTTON_COLOR = 'CHANGE_BUTTON_COLOR';

export const BOOKING_DATES = 'BOOKING_DATES';
export const BOOKING_DATES_CLEAR = 'BOOKING_DATES_CLEAR';
export const BOOKING_TIMES_FROM = 'BOOKING_TIMES_FROM';
export const BOOKING_TIMES_FROM_CLEAR = 'BOOKING_TIMES_FROM_CLEAR';
export const BOOKING_TIMES_TO = 'BOOKING_TIMES_TO';
export const BOOKING_TIMES_TO_CLEAR = 'BOOKING_TIMES_TO_CLEAR';
export const BOOKING_PEOPLE = 'BOOKING_PEOPLE';
export const BOOKING_PEOPLE_CLEAR = 'BOOKING_PEOPLE_CLEAR';

export const EDIT_BLOCK = 'EDIT_BLOCK';
export const REMOVE_BLOCK = 'REMOVE_BLOCK';

export const EDIT_MESSENGERS_DATA = 'EDIT_MESSENGERS_DATA';
export const EDIT_SOCIAL_DATA = 'EDIT_SOCIAL_DATA';

export const UPDATE_CONFIG_DATA = 'UPDATE_CONFIG_DATA';

export const SET_INSTAGRAM_FEED = 'SET_INSTAGRAM_FEED';
export const SET_YOUTUBE_FEED = 'SET_YOUTUBE_FEED';

export const EDIT_STORY = 'EDIT_STORY';
export const REMOVE_STORY = 'REMOVE_STORY';
export const ROTATE_STORY = 'ROTATE_STORY';
export const IMAGE_STORY_UPLOADED = 'IMAGE_STORY_UPLOADED';

export const CATALOG_SEARCH = 'CATALOG_SEARCH';
export const CATALOG_FILTER = 'CATALOG_FILTER';

export const CATALOG_FILTER_HEADER = 'CATALOG_FILTER_HEADER';

export const CATALOG_DRINK_FILTER = 'CATALOG_DRINK_FILTER';

export const CATALOG_DRINK_FILTER_HEADER = 'CATALOG_DRINK_FILTER_HEADER';

export const CATALOG_ORDER = 'CATALOG_ORDER';

export const CATALOG_ORDER_CLEAR = 'CATALOG_ORDER_CLEAR';

export const CATALOG_TABLE_FILTER = 'CATALOG_TABLE_FILTER';

export const CATALOG_TABLE_FILTER_HEADER = 'CATALOG_TABLE_FILTER_HEADER';

export const CATALOG_TABLE_ORDER = 'CATALOG_TABLE_ORDER';

export const CATALOG_TABLE_ORDER_CLEAR = 'CATALOG_TABLE_ORDER_CLEAR';

export const ORDER_TABLES  = 'ORDER_TABLES';

export const ORDER_TABLES_CLEAR  = 'ORDER_TABLES_CLEAR';


