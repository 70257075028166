import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import './index.scss';

const ReviewsOnly = () => {

    const { reviews = [] } = useSelector((state) => state.config.data);
    const reviews0 = reviews[0];
    const placeId = reviews0.value;
    const linkUrl = `https://search.google.com/local/writereview?placeid=${placeId}`;
    
    useEffect(() => {
        if (placeId !== "") {
            window.open(linkUrl, "_self");
        }
    }, []);

    return (
        <div />
    );
};

export default ReviewsOnly;
