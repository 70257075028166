import React from 'react';

import PropTypes from 'prop-types';
import classnames from 'classnames';
import { select } from 'redux-saga/effects';
import menuOnline from 'images/empty.png';
import Button from 'components/common/Button';
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';
import { __ } from 'utils/translation';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './index.scss';
import 'components/StoriesTables/StoryTable/index.scss';

import { translatedProperty } from 'utils/translation';


const StoryTable = (props) => {

    const {
        image,
        onClick,
        className,
        selected,
        scrollPosition
    } = props;
    const title = translatedProperty(props, "title")


    const storyTable = (

        <div className={classnames(["story-table-picture", className])}
            onClick={onClick}
        >
            {!selected  && <div className="story-table-picture__box" style={{ backgroundImage: `URL(${image})` }} />}
            {selected  && <div className="story-table-picture-selected__box" style={{ backgroundImage: `URL(${image})` }} />}
            {/* {!selected  && <Button onClick={onClick} className="story-table-picture-title">{title}</Button>} */}
            {selected  && <Button onClick={onClick} className="story-table-picture-title-selected">{title}</Button>}

        </div>
    );
    return storyTable;
}

StoryTable.propTypes = {
    title: PropTypes.string,
    image: PropTypes.string,

};

StoryTable.defaultProps = {
    title: '',
    image: undefined,

};

export default StoryTable;
