import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'components/common/Button';
import './index.scss';

const Title = () => {
  const [data, setData] = useState({ title: '', description: '' });

  const { title, description } = useSelector((state) => state.config.data);


  useEffect(() => {
    setData({ title, description });
  }, [title, description]);

  if (title) {
    return (
      <React.Fragment>
        <Button className="title-button" onClick={() => { }}>
          <h1 className="title" >{title || 'Header your app'}</h1>
          <div className="description" >{description ||
            'Descriptions your app'}</div>
        </Button>

      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      <div className="theme2-empty" ></div>
    </React.Fragment>
  );
};


export default Title;
