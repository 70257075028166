import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'components/common/Slider';
import Input from 'components/common/Input';
import Button from 'components/common/Button';
import Icon from 'components/common/Icon';
import Textarea from 'components/common/Textarea';
import './index.scss';

import { CATALOG_ORDER, CATALOG_ORDER_CLEAR } from 'constants/actions';
import API from 'utils/api';
import { __ } from 'utils/translation';
import { getSearchParams } from 'utils/url';
import { parse } from 'superagent';
import { translatedProperty } from 'utils/translation';
import Review from 'components/Review';
import { ControlCameraOutlined } from '@material-ui/icons';

const Order = ({ title }) => {
    const [orderOpened, setOrderOpened] = useState(false);
    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [comment, setComment] = useState("");
    const [sent, setSent] = useState(false);
    const { order } = useSelector((state) => state.config);
    const { catalogItems = [] } = useSelector((state) => state.config.data);
    const { catalogDrinks = [] } = useSelector((state) => state.config.data);

    const { catalog2Items = [] } = useSelector((state) => state.config.data);
    const { catalogBottles = [] } = useSelector((state) => state.config.data);
    const { catalogCakes = [] } = useSelector((state) => state.config.data);
    const { catalogCigarettas = [] } = useSelector((state) => state.config.data);
    const { catalogDishes = [] } = useSelector((state) => state.config.data);
    const { catalogSnacks = [] } = useSelector((state) => state.config.data);
    const { catalogSoftDrinks = [] } = useSelector((state) => state.config.data);
    const { catalogOffers = [] } = useSelector((state) => state.config.data);
    const { catalog3Items = [] } = useSelector((state) => state.config.data);
    const dispatch = useDispatch();
    const [timer, setTimer] = useState(null);
    const [orderProps, setOrderProps] = useState({});
    const [visualProps, setVisualProps] = useState([]);
    const { settingsName } = useSelector((state) => state.config.data);
    var params = getSearchParams(window.location.search);
    const { lang } = useSelector((state) => state.config.config);
    const allCatalogs = catalogItems.concat(
        catalogDrinks, catalogSoftDrinks, catalogSnacks, catalogDishes,
        catalogCigarettas, catalogCakes, catalogBottles, catalog2Items, 
        catalog3Items, catalogOffers); 


    useEffect(() => {
        const isPwa = params['pwa'] === "" || params['pwa'];

        const ignoredParams = ["pwa", "demo"];
        const props = {}
        const vprops = [];
        props[__(isPwa ? "Отправлено из установленного приложения" : "Отправлено с сайта", lang ?? "es")] = "";
        for (var propName in params) {
            if (propName && !ignoredParams.includes(propName)) {
                props[propName] = params[propName];
                vprops.push({ key: propName, value: params[propName] });
            }
        }
        setOrderProps(props);
        setVisualProps(vprops);
    }, []);



    let isRestauranteEs = false;
    if (params['mesa'] === "" || params['mesa']) {
        isRestauranteEs = true;
    };
    let isRestauranteEn = false;
    if (params['table'] === "" || params['table']) {
        isRestauranteEn = true;
    };
    let isRestauranteRu = false;
    if (params['стол'] === "" || params['стол']) {
        isRestauranteRu = true;
    };
    let isRestauranteIt = false;
    if (params['tavolo'] === "" || params['tavolo']) {
        isRestauranteIt = true;
    };
    let isRestauranteFr = false;
    if (params['table'] === "" || params['table']) {
        isRestauranteFr = true;
    };
    let isRestauranteDe = false;
    if (params['tabelle'] === "" || params['tabelle']) {
        isRestauranteDe = true;
    };
    let isRestauranteZh = false;
    if (params['表'] === "" || params['表']) {
        isRestauranteZh = true;
    };
    let isWaiter = false;
    if (params['waiter'] === "" || params['waiter']) {
        isWaiter = true;
    };
    let isSelfService = false;
    if (params['selfservice'] === "" || params['selfservice']) {
        isSelfService = true;
    };
    let isWaiterSelfService = false;
    if (params['waiterselfservice'] === "" || params['waiterselfservice']) {
        isWaiterSelfService = true;
    };
    let isDelivery = false;
    if (params['delivery'] === "" || params['delivery']) {
        isDelivery = true;
    };
    let isTakeAway = false;
    if (params['takeaway'] === "" || params['takeaway']) {
        isTakeAway = true;
    };
    let isIAmWaiter = false;
    if (params['iamwaiter'] === "" || params['iamwaiter']) {
        isIAmWaiter = true;
    };
    let isTable = false;
    if (isRestauranteEs || isRestauranteEn || isRestauranteRu || isRestauranteIt || isRestauranteFr || isRestauranteDe || isRestauranteZh) {
        isTable = true
    };
    let isNotSendOrderNotCallWaiter = false;
    if (isTable && !isWaiter && !isSelfService) {
        isNotSendOrderNotCallWaiter = true
    };
    let isCallWaiterNotSendOrder = false;
    if (isTable && isWaiter && !isSelfService) {
        isCallWaiterNotSendOrder = true
    };
    let isSendOrderNotCallWaiter = false;
    if (isTable && isSelfService) {
        isSendOrderNotCallWaiter = true
    };
    let isSendOrderAndCallWaiter = false;
    if (isTable && isWaiterSelfService) {
        isSendOrderAndCallWaiter = true
    };


    const orderItems = [];

    for (var propName in order) {
        if (order[propName].count !== 0) {
            orderItems.push({
                guid: propName,
                count: order[propName].count,
                sum: order[propName].sum,
                currency: order[propName].currency,
                orderItems: order[propName].orderItems,
                nameEmptyItem: order[propName].nameEmptyItem,
                price: order[propName].price,
            });
        }
    }
    const orderSum = orderItems.length === 0 ? 0 :
        orderItems.reduce((a, e) => { return { sum: a.sum + e.sum } }).sum;
    const currency = orderItems[0]?.currency;



    if (orderSum === 0 && !(isCallWaiterNotSendOrder || isSendOrderAndCallWaiter))
        return null;

    const handleClear = () => {
        dispatch({ type: CATALOG_ORDER_CLEAR });
        setOrderOpened(false);
    }

    const handleRemove = (orderItem) => {
        dispatch({
            type: CATALOG_ORDER,
            guid: orderItem.guid,
            count: 0,
            sum: 0,
            currency
        });
    }


    const handleSubmitIAmWaiter = () => {
        setOrderOpened(false);
        API.sendOrder({
            items: orderItems.map(orderItem => {
                const catalogItem = allCatalogs.filter(e => e.guid === orderItem.guid)[0];
                return {
                    number: catalogItem?.number,
                    text: catalogItem?.text ? translatedProperty(catalogItem, "text", lang ?? "en") : orderItem.nameEmptyItem ,
                    description: translatedProperty(catalogItem, "textAlt", lang ?? "en"),
                    price: catalogItem?.price ? parseFloat(catalogItem?.price) : parseFloat(orderItem?.price),
                    count: orderItem.count,
                    sum: parseFloat(orderItem.sum.toFixed(2)),
                    currency: orderItem.currency
                };
            }),
            total: parseFloat(orderSum.toFixed(2)),
            currency: currency,
            mobile: phone,
            comment: comment,
            address: address,
            title: title,
            copy: "1",
            user: settingsName,
            name: name,
            waiter: __("Официант"),
            namewaiter: nameWaiter,
            tabelNumber: tabelNumber,
            table: __("Стол"),
            type: "delivery",
            cta: description,
            status: "new"

        }).then(() => {
            setTimer(1);
            setSent(true)

        });
        setTimeout(() => {
            handleClear()
            setSent(false)
        }, 5000);

    };

    const handleSubmit = () => {
        setOrderOpened(false);
        API.sendOrder({
            items: orderItems.map(orderItem => {
                const catalogItem = allCatalogs.filter(e => e.guid === orderItem.guid)[0];
                return {
                    number: catalogItem?.number,
                    text: translatedProperty(catalogItem, "text", lang ?? "en"),
                    description: translatedProperty(catalogItem, "textAlt", lang ?? "en"),
                    price: catalogItem?.price ? parseFloat(catalogItem?.price) : null,
                    count: orderItem.count,
                    sum: parseFloat(orderItem.sum.toFixed(2)),
                    currency: orderItem.currency
                };
            }),
            total: parseFloat(orderSum.toFixed(2)),
            currency: currency,
            mobile: phone,
            comment: comment,
            address: address,
            title: title,
            copy: "1",
            user: settingsName,
            name: name,
            waiter: __("Официант"),
            namewaiter: nameWaiter,
            tabelNumber: tabelNumber,
            table: __("Стол"),
            type: "delivery",
            cta: description,
            status: "new"

        }).then(() => {
            setTimer(6);
            setSent(true);
        });
        setTimeout(() => {
            handleClear()
            setSent(false)
        }, 60000);
    };

    const handleSubmit1 = () => {
        setOrderOpened(false);
        API.sendOrder({
            items: orderItems.map(orderItem => {
                const catalogItem = allCatalogs.filter(e => e.guid === orderItem.guid)[0];
                return {
                    number: catalogItem?.number,
                    text: translatedProperty(catalogItem, "text", lang ?? "en"),
                    description: translatedProperty(catalogItem, "textAlt", lang ?? "en"),
                    price: catalogItem?.price ? parseFloat(catalogItem?.price) : null,
                    count: orderItem.count,
                    sum: parseFloat(orderItem.sum.toFixed(2)),
                    currency: orderItem.currency
                };
            }),
            total: parseFloat(orderSum.toFixed(2)),
            currency: currency,
            mobile: phone,
            comment: comment,
            address: address,
            title: title,
            copy: "1",
            user: settingsName,
            name: name,
            waiter: __("Официант"),
            namewaiter: nameWaiter,
            tabelNumber: tabelNumber,
            table: __("Стол"),
            type: "delivery",
            cta: __("Вызов к столику, новый заказ"),
            status: "new"

        }).then(() => {
            setTimer(6);
            setSent(true);
        });
        setTimeout(() => {
            handleClear()
            setSent(false)
        }, 60000);

    };

    const handleSubmit2 = () => {
        setOrderOpened(false);
        API.sendOrder({
            items: orderItems.map(orderItem => {
                const catalogItem = allCatalogs.filter(e => e.guid === orderItem.guid)[0];
                return {
                    number: catalogItem?.number,
                    text: translatedProperty(catalogItem, "text", lang ?? "en"),
                    description: translatedProperty(catalogItem, "textAlt", lang ?? "en"),
                    price: catalogItem?.price ? parseFloat(catalogItem?.price) : null,
                    count: orderItem.count,
                    sum: parseFloat(orderItem.sum.toFixed(2)),
                    currency: orderItem.currency
                };
            }),
            total: parseFloat(orderSum.toFixed(2)),
            currency: currency,
            mobile: phone,
            comment: comment,
            address: address,
            title: title,
            copy: "1",
            user: settingsName,
            name: name,
            waiter: __("Официант"),
            namewaiter: nameWaiter,
            tabelNumber: tabelNumber,
            table: __("Стол"),
            type: "delivery",
            cta: __("Вызов к столику, оплата по карте"),
            status: "new"

        }).then(() => {
            setTimer(6);
            setSent(true);
        });
        setTimeout(() => {
            handleClear()
            setSent(false)
        }, 60000);
    };

    const handleSubmit3 = () => {
        setOrderOpened(false);
        API.sendOrder({
            items: orderItems.map(orderItem => {
                const catalogItem = allCatalogs.filter(e => e.guid === orderItem.guid)[0];
                return {
                    number: catalogItem?.number,
                    text: translatedProperty(catalogItem, "text", lang ?? "en"),
                    description: translatedProperty(catalogItem, "textAlt", lang ?? "en"),
                    price: catalogItem?.price ? parseFloat(catalogItem?.price) : null,
                    count: orderItem.count,
                    sum: parseFloat(orderItem.sum.toFixed(2)),
                    currency: orderItem.currency
                };
            }),
            total: parseFloat(orderSum.toFixed(2)),
            currency: currency,
            mobile: phone,
            comment: comment,
            address: address,
            title: title,
            copy: "1",
            user: settingsName,
            name: name,
            waiter: __("Официант"),
            namewaiter: nameWaiter,
            tabelNumber: tabelNumber,
            table: __("Стол"),
            type: "delivery",
            cta: __("Вызов к столику, оплата наличными"),
            status: "new"

        }).then(() => {
            setTimer(6);
            setSent(true);
        });
        setTimeout(() => {
            handleClear()
            setSent(false)
        }, 60000);
    };


    const time = new Date().toLocaleString().replace(",", "");

    let description = "";
    let tabelNumber = "";
    let nameWaiter = "";
    if (isTakeAway) {
        console.log("isTakeAway", isTakeAway);
        console.log("orderItems", orderItems)
        description = __("Заказ на вынос");
        // кнопка  когда сумма 0
        if (orderSum === 0 && !orderOpened)
            return null;
        // кнопка и слайдер когда сумма !0
        return (
            //  кнопка когда сумма !0
            <>
                <div className="publish">
                    {!sent && !orderOpened &&
                        <Button className="publish__button"
                            onClick={() => setOrderOpened(true)}
                            noStyled>
                            {__("Ваш заказ на сумму:")} {orderSum.toFixed(2)} {currency}
                        </Button>
                    }
                    {!sent && !orderOpened &&
                        <div className="publish-notification"><b>{__("НАЖМИ, ЧТОБЫ ПОСМОТРЕТЬ ЗАКАЗ")}</b></div>
                    }
                    {sent && !orderOpened &&
                        <Button
                            className="publish__button__copy__already"
                            noStyled>
                            {__("Заказ успешно отправлен")}{timer % 3 === 0 ? "..." : timer % 3 === 1 ? ".." : "."}
                        </Button>
                    }
                    {/* слайдер когда сумма !0 */}
                    {!sent && orderOpened &&
                        <Slider
                            opened={orderOpened}
                            title={__("Ваш предварительный заказ")}
                            subtitle={__("Пожалуйста укажите контактную информацию и отправьте ваш заказ. Мы ответим вам в самое ближайшее время")}
                            submitTitle={__("ОТПРАВИТЬ").toUpperCase()}
                            onRemove={handleClear}
                            onClose={() => setOrderOpened(false)}
                            onSubmit={handleSubmit}
                        >
                            <div className="order__screenshot-area">

                                {orderItems.map(orderItem => {
                                    const catalogItem = allCatalogs.filter(e => e.guid === orderItem.guid)[0];

                                    return (

                                        <div className="order__description" key={orderItem.guid}>
                                            <Button className="order-remove" onClick={() => handleRemove(orderItem)}
                                                isInline noStyled>
                                                <Icon type="trash" noStyled />
                                            </Button>

                                            {catalogItem?.number ? <>&#8470;<b>{catalogItem?.number}</b> | </> : null}{catalogItem?.textEs}
                                            &nbsp;
                                            {catalogItem?.textAltEs}
                                            : &nbsp; <b>
                                                {orderItem.count}
                                            </b> x &nbsp;
                                            {catalogItem?.price}
                                            {orderItem.currency}
                                            =
                                            {parseFloat(orderItem.sum).toFixed(2)}
                                            {orderItem.currency}
                                        </div>
                                    );
                                })}
                                <div className="order__total">{__("Итого:")} {orderSum.toFixed(2)} {currency}</div>

                            </div>
                            <React.Fragment>
                                <Input
                                    className="order__input"
                                    value={phone}
                                    type="tel"
                                    placeholder={__("Напишите свой телефон")}
                                    onChange={(value) => setPhone(value)}
                                />
                                <Input
                                    className="order__input"
                                    value={name}
                                    type="text"
                                    placeholder={__("Напишите своё имя")}
                                    onChange={(value) => setName(value)}
                                />
                                <div className="order__input__descriptions">{__("Информация не будет передана третьим лицам")}</div>
                            </React.Fragment>
                        </Slider>
                    }
                </div>
            </>
        )
    } else if (isIAmWaiter) {
        console.log("isIAmWaiter", isIAmWaiter);
        tabelNumber = (visualProps.slice(0, 1).map(e => e.value).toString().replace(",", ""));
        nameWaiter = (visualProps.slice(1, 2).map(e => e.value).toString().replace(",", ""));
        console.log("tabelNumber", tabelNumber);
        console.log("nameWaiter", nameWaiter);
        description = __("Заказ от нашего официанта");
        if (orderSum === 0 && !orderOpened)
            return (
                <React.Fragment>
                    <div className="publish">
                        <Button className="publish__button"
                            onClick={() => setOrderOpened(true)}
                            noStyled>
                            {__("Вызвать официанта")}
                        </Button>
                        <div className="publish-notification__red"><b>{__("НАЖМИ")}</b>&nbsp;
                            <Icon type="plusCircle" className="order-add-button" />&nbsp;
                            <b>{__("ЧТОБЫ ДОБАВИТЬ БЛЮДО К ЗАКАЗУ")}</b></div>
                    </div>
                </React.Fragment>);
        // кнопка  когда сумма !0
        return (
            <>
                <div className="publish">
                    {orderSum !== 0 && !sent && !orderOpened &&
                        <Button className="publish__button"
                            onClick={() => setOrderOpened(true)}
                            noStyled>
                            {__("Ваш заказ на сумму:")} {orderSum.toFixed(2)} {currency}
                        </Button>
                    }
                    {!sent && !orderOpened &&
                        <div className="publish-notification"><b>{__("НАЖМИ, ЧТОБЫ ПОСМОТРЕТЬ ЗАКАЗ")}</b></div>
                    }


                    {orderSum !== 0 && sent && !orderOpened &&
                        <Button
                            className="publish__button__copy__already"
                            noStyled>
                            {__("Заказ успешно отправлен")}{timer % 3 === 0 ? "..." : timer % 3 === 1 ? ".." : "."}
                        </Button>
                    }
                    {/* Слайдер когда не добавлено ни одного блюда к заказу */}

                    {/* Слайдер когда добавленs  блюда к заказу */}
                    {!sent && orderSum !== 0 && orderOpened &&
                        <Slider
                            opened={orderOpened}
                            title={__("Ваш предварительный заказ")}
                            onSubmit={handleSubmitIAmWaiter}
                            submitTitle={__("ОТПРАВИТЬ").toUpperCase()}
                            onRemove={handleClear}
                            onClose={() => setOrderOpened(false)}

                        >
                            <div className="order__screenshot-area">
                                <div className="order-flex-row">
                                    <div className="order__prop">{__("Стол")}:&nbsp;</div>
                                    {visualProps.slice(0, 1).map(e => <div className="order__prop" key={e.key}>{e.value}</div>)}
                                </div>
                                <div className="order-flex-row">
                                    <div className="order__prop">{__("Официант")}:&nbsp;</div>
                                    {visualProps.slice(1, 2).map(e => <div className="order__prop" key={e.key}>{e.value}</div>)}
                                </div>
                                <br />
                                {orderItems.map(orderItem => {
                                    const catalogItem = allCatalogs.filter(e => e.guid === orderItem.guid)[0];
                                    
                                    return (

                                        <div className="order__description" key={orderItem.guid}>
                                            <Button className="order-remove" onClick={() => handleRemove(orderItem)}
                                                isInline noStyled>
                                                <Icon type="trash" noStyled />
                                            </Button>

                                            {catalogItem?.number ? <>&#8470;<b>{catalogItem?.number}</b> | </> : null}
                                            {catalogItem?.textEs}{orderItem?.nameEmptyItem} &nbsp;
                                            {catalogItem?.textAltEs}
                                            : &nbsp;
                                            <b> {orderItem.count} </b>
                                            x &nbsp;
                                            {catalogItem?.price}{orderItem?.price}
                                            {orderItem.currency}
                                            =
                                            {parseFloat(orderItem.sum).toFixed(2)}
                                            {orderItem.currency}
                                        </div>
                                    );
                                })}
                                <div className="order__total">{__("Итого:")} {orderSum.toFixed(2)} {currency}{lang}</div>
                            </div>
                            <div className="order__screenshot-area__description">
                                <Icon type="camera" className="order__screenshot-area__description__icon" />&nbsp;
                                <b>{__("Область для снимка экрана")}</b>
                            </div>
                            <>
                                <Textarea
                                    className="order__input"
                                    value={comment}
                                    type="text"
                                    placeholder={__("Напишите в этом поле комментарий к заказу, укажите альтернативный способ связи или задайте свой вопрос. ЗАПОЛНЕНИЕ НЕ ОБЯЗАТЕЛЬНО")}
                                    onChange={(value) => setComment(value)}
                                />
                                <div className="order__input__descriptions-minus-margin"></div>
                                <div className="order__input__descriptions">{__("Информация не будет передана третьим лицам")}</div>
                            </>
                        </Slider>}
                </div>
            </>
        )
    } else if (isDelivery) {
        console.log("isDelivery", isDelivery)
        description = __("Заказ на доставку");
        // кнопка  когда сумма 0
        if (orderSum === 0 && !orderOpened)
            return null;
        // кнопка и слайдер когда сумма !0
        return (
            //  кнопка когда сумма !0
            <>
                <div className="publish">
                    {!sent && !orderOpened &&
                        <Button className="publish__button"
                            onClick={() => setOrderOpened(true)}
                            noStyled>
                            {__("Ваш заказ на сумму:")} {orderSum.toFixed(2)} {currency}
                        </Button>
                    }
                    {!sent && !orderOpened &&
                        <div className="publish-notification"><b>{__("НАЖМИ, ЧТОБЫ ПОСМОТРЕТЬ ЗАКАЗ")}</b></div>
                    }
                    {sent && !orderOpened &&
                        <Button
                            className="publish__button__copy__already"
                            noStyled>
                            {__("Заказ успешно отправлен")}{timer % 3 === 0 ? "..." : timer % 3 === 1 ? ".." : "."}
                        </Button>
                    }
                    {/* слайдер когда сумма !0 */}
                    {!sent && orderOpened &&
                        <Slider
                            opened={orderOpened}
                            title={__("Ваш предварительный заказ")}
                            subtitle={__("Пожалуйста укажите контактную информацию и отправьте ваш заказ. Мы ответим вам в самое ближайшее время")}
                            submitTitle={__("ОТПРАВИТЬ").toUpperCase()}
                            onRemove={handleClear}
                            onClose={() => setOrderOpened(false)}
                            onSubmit={handleSubmit}
                        >
                            <div className="order__screenshot-area">

                                {orderItems.map(orderItem => {
                                    const catalogItem = allCatalogs.filter(e => e.guid === orderItem.guid)[0];

                                    return (

                                        <div className="order__description" key={orderItem.guid}>
                                            <Button className="order-remove" onClick={() => handleRemove(orderItem)}
                                                isInline noStyled>
                                                <Icon type="trash" noStyled />
                                            </Button>

                                            {catalogItem?.number ? <>&#8470;<b>{catalogItem?.number}</b> | </> : null}{catalogItem?.textEs}
                                            &nbsp;
                                            {catalogItem?.textAltEs}
                                            : &nbsp; <b>
                                                {orderItem.count}
                                            </b> x &nbsp;
                                            {catalogItem?.price}
                                            {orderItem.currency}
                                            =
                                            {parseFloat(orderItem.sum).toFixed(2)}
                                            {orderItem.currency}
                                        </div>
                                    );
                                })}
                                <div className="order__total">{__("Итого:")} {orderSum.toFixed(2)} {currency}</div>

                            </div>
                            <React.Fragment>
                                <Input
                                    className="order__input"
                                    value={phone}
                                    type="tel"
                                    placeholder={__("Напишите свой телефон")}
                                    onChange={(value) => setPhone(value)}
                                />
                                <Input
                                    className="order__input"
                                    value={name}
                                    type="text"
                                    placeholder={__("Напишите своё имя")}
                                    onChange={(value) => setName(value)}
                                />
                                <Input
                                    className="order__input"
                                    value={address}
                                    type="text"
                                    placeholder={__("Напишите свой адрес")}
                                    onChange={(value) => setAddress(value)}
                                />
                                <Textarea
                                    className="order__input"
                                    value={comment}
                                    type="text"
                                    placeholder={__("Напишите в этом поле комментарий к заказу, укажите альтернативный способ связи или задайте свой вопрос. ЗАПОЛНЕНИЕ НЕ ОБЯЗАТЕЛЬНО")}
                                    onChange={(value) => setComment(value)}
                                />
                                <div className="order__input__descriptions">{__("Информация не будет передана третьим лицам")}</div>
                            </React.Fragment>
                        </Slider>
                    }
                </div>
            </>)
    } else if (isSendOrderAndCallWaiter) {
        tabelNumber = (visualProps.map(e => e.value).toString().replace(",", ""));
        if (orderSum === 0 && !orderOpened)
            return <React.Fragment>
                <div className="publish">
                    <Button className="publish__button"
                        onClick={() => setOrderOpened(true)}
                        noStyled>
                        {__("Вызвать официанта")}
                    </Button>
                    <div className="publish-notification__red"><b>{__("НАЖМИ")}</b>&nbsp;
                        <Icon type="plusCircle" className="order-add-button" />&nbsp;
                        <b>{__("ЧТОБЫ ДОБАВИТЬ БЛЮДО К ЗАКАЗУ")}</b></div>
                </div>
            </React.Fragment>;
        // кнопка  когда сумма !0
        return (
            <>
                <div className="publish">
                    {orderSum !== 0 && !sent && !orderOpened &&
                        <Button className="publish__button"
                            onClick={() => setOrderOpened(true)}
                            noStyled>
                            {__("Ваш заказ на сумму:")} {orderSum.toFixed(2)} {currency}
                        </Button>
                    }
                    {!sent && !orderOpened &&
                        <div className="publish-notification"><b>{__("НАЖМИ, ЧТОБЫ ПОСМОТРЕТЬ ЗАКАЗ")}</b></div>
                    }


                    {orderSum !== 0 && sent && !orderOpened &&
                        <Button
                            className="publish__button__copy__already"
                            noStyled>
                            {__("Заказ успешно отправлен")}{timer % 3 === 0 ? "..." : timer % 3 === 1 ? ".." : "."}
                        </Button>
                    }
                    {/* Слайдер когда не добавлено ни одного блюда к заказу */}
                    {orderSum === 0 && orderOpened &&
                        <Slider
                            opened={orderOpened}
                            title={__("Вызвать официанта")}
                            subtitle={__("Нажмите нужную кнопку, чтобы позвать нашего официанта")}
                            onSubmit={() => setOrderOpened(false)}
                            submitTitle={__("ЗАКРЫТЬ")?.toUpperCase()}
                            onClose={() => setOrderOpened(false)}

                        >
                            <div className="order-flex-row">
                                <div className="order__prop">{__("Стол")}:&nbsp;</div>
                                {visualProps.map(e => <div className="order__prop" key={e.key}>{e.value}</div>)}
                            </div>
                            <br />
                            <React.Fragment>
                                <Button className="publish__button-caller"
                                    onClick={handleSubmit1}
                                    noStyled>
                                    {__("ХОЧУ СДЕЛАТЬ ЗАКАЗ")}
                                </Button>
                                <Textarea
                                    className="order__input"
                                    value={comment}
                                    type="text"
                                    placeholder={__("В свободной форме опишите свой заказ и отправьте команду для нас. Мы обслужим вас в приоритете.")}
                                    onChange={(value) => setComment(value)}
                                />
                                <Button className="publish__button-caller"
                                    onClick={handleSubmit2}
                                    noStyled>
                                    {__("ХОЧУ ОПЛАТИТЬ КАРТОЙ")}
                                </Button>
                                <Button className="publish__button-caller"
                                    onClick={handleSubmit3}
                                    noStyled>
                                    {__("ХОЧУ ОПЛАТИТЬ НАЛИЧНЫМИ")}
                                </Button>
                                <div className="order__input__descriptions-minus-margin"></div>
                                <div className="order__input__descriptions">{__("Информация не будет передана третьим лицам")}</div>
                                <br />
                            </React.Fragment>

                        </Slider>}
                    {/* Слайдер когда добавленs  блюда к заказу */}
                    {!sent && orderSum !== 0 && orderOpened &&
                        <Slider
                            opened={orderOpened}
                            title={__("Ваш предварительный заказ")}
                            subtitle={__("Нажмите нужную кнопку, чтобы позвать нашего официанта")}
                            onSubmit={handleSubmit}
                            submitTitle={__("ЗАКРЫТЬ")?.toUpperCase()}
                            onRemove={handleClear}
                            onClose={() => setOrderOpened(false)}

                        >
                            <div className="order__screenshot-area">
                                <div className="order-flex-row">
                                    <div className="order__prop">{__("Стол")}:&nbsp;</div>
                                    {visualProps.map(e => <div className="order__prop" key={e.key}>{e.value}</div>)}
                                </div>
                                <br />
                                {orderItems.map(orderItem => {
                                    const catalogItem = allCatalogs.filter(e => e.guid === orderItem.guid)[0];

                                    return (

                                        <div className="order__description" key={orderItem.guid}>
                                            <Button className="order-remove" onClick={() => handleRemove(orderItem)}
                                                isInline noStyled>
                                                <Icon type="trash" noStyled />
                                            </Button>

                                            {catalogItem?.number ? <>&#8470;<b>{catalogItem?.number}</b> | </> : null}{catalogItem?.textEs}
                                            &nbsp;
                                            {catalogItem?.textAltEs}
                                            : &nbsp; <b>
                                                {orderItem.count}
                                            </b> x &nbsp;
                                            {catalogItem?.price}
                                            {orderItem.currency}
                                            =
                                            {parseFloat(orderItem.sum).toFixed(2)}
                                            {orderItem.currency}
                                        </div>
                                    );
                                })}
                                <div className="order__total">{__("Итого:")} {orderSum.toFixed(2)} {currency}{lang}</div>
                            </div>
                            <div className="order__screenshot-area__description">
                                <Icon type="camera" className="order__screenshot-area__description__icon" />&nbsp;
                                <b>{__("Область для снимка экрана")}</b>
                            </div>
                            <>
                                <Button className="publish__button-caller"
                                    onClick={handleSubmit1}
                                    noStyled>
                                    {__("ХОЧУ СДЕЛАТЬ ЗАКАЗ")}
                                </Button>
                                <Button className="publish__button-caller"
                                    onClick={handleSubmit2}
                                    noStyled>
                                    {__("ХОЧУ ОПЛАТИТЬ КАРТОЙ")}
                                </Button>
                                <Button className="publish__button-caller"
                                    onClick={handleSubmit3}
                                    noStyled>
                                    {__("ХОЧУ ОПЛАТИТЬ НАЛИЧНЫМИ")}
                                </Button>
                                <Textarea
                                    className="order__input"
                                    value={comment}
                                    type="text"
                                    placeholder={__("Какие у вас купюры? Мы принесем вас сдачу!")}
                                    onChange={(value) => setComment(value)}
                                />
                                <div className="order__input__descriptions-minus-margin"></div>
                                <div className="order__input__descriptions">{__("Информация не будет передана третьим лицам")}</div>
                            </>
                        </Slider>}
                </div>
            </>
        )
    } else if (isSendOrderNotCallWaiter) {
        console.log("isSendOrderNotCallWaiter", isSendOrderNotCallWaiter)
        tabelNumber = (visualProps.map(e => e.value).toString().replace(",", ""));
        // кнопка  когда сумма 0
        if (orderSum === 0 && !orderOpened)
            return null;
        // кнопка и слайдер когда сумма !0
        return (
            //  кнопка когда сумма !0
            <>
                <div className="publish">
                    {!sent && !orderOpened &&
                        <Button className="publish__button"
                            onClick={() => setOrderOpened(true)}
                            noStyled>
                            {__("Ваш заказ на сумму:")} {orderSum.toFixed(2)} {currency}
                        </Button>
                    }
                    {!sent && !orderOpened &&
                        <div className="publish-notification"><b>{__("НАЖМИ, ЧТОБЫ ПОСМОТРЕТЬ ЗАКАЗ")}</b></div>
                    }
                    {sent && !orderOpened &&
                        <Button
                            className="publish__button__copy__already"
                            noStyled>
                            {__("Заказ успешно отправлен")}{timer % 3 === 0 ? "..." : timer % 3 === 1 ? ".." : "."}
                        </Button>
                    }
                    {/* слайдер когда сумма !0 */}
                    {!sent && orderOpened &&
                        <Slider
                            opened={orderOpened}
                            title={__("Ваш предварительный заказ")}
                            subtitle={__("Пожалуйста укажите контактную информацию и отправьте ваш заказ. Мы ответим вам в самое ближайшее время")}
                            submitTitle={__("ОТПРАВИТЬ").toUpperCase()}
                            onRemove={handleClear}
                            onClose={() => setOrderOpened(false)}
                            onSubmit={handleSubmit}
                        >
                            <div className="order__screenshot-area">
                                <div className="order-flex-row">
                                    <div className="order__prop">{__("Стол")}:&nbsp;</div>
                                    {visualProps.map(e => <div className="order__prop" key={e.key}>{e.value}</div>)}
                                </div>
                                <br />
                                {orderItems.map(orderItem => {
                                    const catalogItem = allCatalogs.filter(e => e.guid === orderItem.guid)[0];

                                    return (

                                        <div className="order__description" key={orderItem.guid}>
                                            <Button className="order-remove" onClick={() => handleRemove(orderItem)}
                                                isInline noStyled>
                                                <Icon type="trash" noStyled />
                                            </Button>

                                            {catalogItem?.number ? <>&#8470;<b>{catalogItem?.number}</b> | </> : null}{catalogItem?.textEs}
                                            &nbsp;
                                            {catalogItem?.textAltEs}
                                            : &nbsp; <b>
                                                {orderItem.count}
                                            </b> x &nbsp;
                                            {catalogItem?.price}
                                            {orderItem.currency}
                                            =
                                            {parseFloat(orderItem.sum).toFixed(2)}
                                            {orderItem.currency}
                                        </div>
                                    );
                                })}
                                <div className="order__total">{__("Итого:")} {orderSum.toFixed(2)} {currency}</div>

                            </div>
                        </Slider>
                    }
                </div>
            </>)


    } else if (isCallWaiterNotSendOrder) {
        console.log("isCallWaiterNotSendOrder", isCallWaiterNotSendOrder)
        description = __("Новый заказ со столика");
        tabelNumber = (visualProps.map(e => e.value).toString().replace(",", ""));
        if (orderSum === 0 && !orderOpened)
            return <React.Fragment>
                <div className="publish">
                    <Button className="publish__button"
                        onClick={() => setOrderOpened(true)}
                        noStyled>
                        {__("Вызвать официанта")}
                    </Button>
                </div>
            </React.Fragment>;
        // кнопка  когда сумма !0
        return (
            <>
                <div className="publish">
                    {!sent && !orderOpened &&
                        <Button className="publish__button"
                            onClick={() => setOrderOpened(true)}
                            noStyled>
                            {__("Вызвать официанта")}
                        </Button>
                    }

                    {!sent && !orderOpened && (isRestauranteEs || isRestauranteEn || isRestauranteRu || isRestauranteDe || isRestauranteFr || isRestauranteIt || isRestauranteZh) &&
                        <div className="publish-notification"><b>{__("НАЖМИ, ЧТОБЫ ПОЗВАТЬ ОФИЦИАНТА")}</b></div>
                    }
                    {/* Слайдер когда не добавлено ни одного блюда к заказу */}
                    {orderOpened &&
                        <Slider
                            opened={orderOpened}
                            title={__("Вызвать официанта")}
                            subtitle={__("Нажмите нужную кнопку, чтобы позвать нашего официанта")}
                            submitTitle={__("ЗАКРЫТЬ")?.toUpperCase()}
                            onSubmit={() => setOrderOpened(false)}
                            onClose={() => setOrderOpened(false)}

                        >
                            <div className="order-flex-row">
                                <div className="order__prop">{__("Стол")}:&nbsp;</div>
                                {visualProps.map(e => <div className="order__prop" key={e.key}>{e.value}</div>)}
                            </div>
                            <br />
                            <React.Fragment>
                                <Button className="publish__button-caller"
                                    onClick={handleSubmit1}
                                    noStyled>
                                    {__("ХОЧУ СДЕЛАТЬ ЗАКАЗ")}
                                </Button>
                                <Textarea
                                    className="order__input"
                                    value={comment}
                                    type="text"
                                    placeholder={__("В свободной форме опишите свой заказ и отправьте команду для нас. Мы обслужим вас в приоритете.")}
                                    onChange={(value) => setComment(value)}
                                />
                                <Button className="publish__button-caller"
                                    onClick={handleSubmit2}
                                    noStyled>
                                    {__("ХОЧУ ОПЛАТИТЬ КАРТОЙ")}
                                </Button>
                                <Button className="publish__button-caller"
                                    onClick={handleSubmit3}
                                    noStyled>
                                    {__("ХОЧУ ОПЛАТИТЬ НАЛИЧНЫМИ")}
                                </Button>
                                <div className="order__input__descriptions-minus-margin"></div>
                                <div className="order__input__descriptions">{__("Информация не будет передана третьим лицам")}</div>
                                <br />
                            </React.Fragment>

                        </Slider>}
                </div>
            </>
        )

    } else {
        console.log("isNotSendOrderNotCallWaiter", isNotSendOrderNotCallWaiter)

        // кнопка  когда сумма 0
        if (orderSum === 0 && !orderOpened)
            return null;
        return (
            //  кнопка когда сумма !0
            <>
                <div className="publish">
                    {!sent && !orderOpened &&
                        <Button className="publish__button"
                            onClick={() => setOrderOpened(true)}
                            noStyled>
                            {__("Ваш заказ на сумму:")} {orderSum.toFixed(2)} {currency}
                        </Button>
                    }
                    {!sent && !orderOpened &&
                        <div className="publish-notification"><b>{__("НАЖМИ, ЧТОБЫ ПОСМОТРЕТЬ ЗАКАЗ")}</b></div>
                    }

                    <Slider
                        opened={orderOpened}
                        title={__("Ваш предварительный заказ")}
                        onSubmit={() => setOrderOpened(false)}
                        submitTitle={__("ЗАКРЫТЬ")?.toUpperCase()}
                        onRemove={handleClear}
                        onClose={() => setOrderOpened(false)}

                    >
                        <div className="order__screenshot-area">
                            {orderItems.map(orderItem => {
                                const catalogItem = allCatalogs.filter(e => e.guid === orderItem.guid)[0];

                                return (

                                    <div className="order__description" key={orderItem.guid}>
                                        <Button className="order-remove" onClick={() => handleRemove(orderItem)}
                                            isInline noStyled>
                                            <Icon type="trash" noStyled />
                                        </Button>

                                        {catalogItem?.number ? <>&#8470;<b>{catalogItem?.number}</b> | </> : null}{catalogItem?.textEs}
                                        &nbsp;
                                        {catalogItem?.textAltEs}
                                        : &nbsp; <b>
                                            {orderItem.count}
                                        </b> x &nbsp;
                                        {catalogItem?.price}
                                        {orderItem.currency}
                                        =
                                        {parseFloat(orderItem.sum).toFixed(2)}
                                        {orderItem.currency}
                                    </div>
                                );
                            })}
                            <div className="order__total">{__("Итого:")} {orderSum.toFixed(2)} {currency}{lang}</div>

                            <div className="order__screenshot-area__description">
                                <Icon type="camera" className="order__screenshot-area__description__icon" />&nbsp;
                                <b>{__("Область для снимка экрана")}</b></div>
                        </div>
                    </Slider>
                </div>
            </>
        )
    }

};

export default Order;