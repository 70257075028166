import React from 'react';
import Button from 'components/common/Button';
import image from 'images/maps_menu_logo.png';
import { __ } from 'utils/translation';

import './index.scss';

const AbamaAd = () => {
    const link ="https://wa.me/message/UJGX56EPFQDIL1";

    return (
        <Button onClick={()=> window.open(link, "_blank")} className="button-abamaAd" >
            <div className="abamaAd">
                <p>
                    <img src={image} alt="Abama" />
                    <div className="abamaAd-textBox">
                        <div className="abamaAd-textBox-title">{__("Закажите сайт и приложение для вашего ресторана:")}</div>
                        <div className="abamaAd-textBox-text">{__("Меню QR, Бронирование столов, Управление заказами, Управление персоналом, Доставка, и другое... ")}</div>
                    </div>
                </p>
            </div>
    </Button>
  );
};


export default AbamaAd;
