import React from 'react';
import PropTypes from 'prop-types';
import { useSelector} from 'react-redux';

import { __ } from 'utils/translation';
import GoogleMap from './GoogleMap';
import './index.scss';



const GoogleMaps = () => {

  const { googleMaps } = useSelector((state) => state.config.data);

  const showGoogleMaps = googleMaps.length !== 0;

  googleMaps.sort((a, b) => b.order - a.order);

  return (
    <React.Fragment>
      <div className="google-maps">
        {
          showGoogleMaps && (
            googleMaps.map((googleMap) => <GoogleMap key={googleMap.guid} {...googleMap} />))
        }
      </div>

    </React.Fragment >
  );
};

GoogleMaps.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({}))
};

GoogleMaps.defaultProps = {
  data: []
};

export default GoogleMaps;
