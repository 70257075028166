import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Button from 'components/common/Button';
import Icon from 'components/common/Icon';

import './index.scss';

const Messengers = () => {
  const [data, setData] = useState([]);

  const { messengers } = useSelector((state) => state.config.data);

  useEffect(() => {
    setData(messengers);
  }, [messengers]);

  const filteredMessengers = data.filter((messenger) => messenger.value !== '');

  const showContact = (link) => {    
      window.open(link, "_blank");    
  };

  const showContactPhone = (link) => {
    if (link.startsWith('tel:')) {
      window.location.href = link;
    }
    else {
      window.open(`tel:${link}`, "_blank");
    }
  };

  const showContactEmail = (link) => {
    if (link.startsWith('mailto:')) {
      window.location.href = link;
    }
    else {
      window.open(`mailto:${link}`, "_blank");
    }
  };

  const showContactWhatsApp = (link) => {
    if (link.startsWith('https://api.whatsapp.com/send?phone=')) {
      window.location.href = link.replace(/[^0-9]/g, '') ;
    }    
    else {
      window.open(`https://api.whatsapp.com/send?phone=${link.replace(/[^0-9]/g, '')}`, "_blank");
    }
  };

  const showContactInstagram = (link) => {
    if (link.startsWith('https://www.instagram.com/')) {
      window.location.href = link;
    }
    if (link.startsWith('https://instagram.com/')) {
      window.location.href = `https://www.instagram.com/${link.replace(/[@]/g, '')}`;
    }
    if (link.startsWith('instagram.com/')) {
      window.location.href = `https://www.instagram.com/${link.replace(/[@]/g, '')}`;
    }
    else {
      window.open( `https://www.instagram.com/${link.replace(/[@]/g, '')}`);
    }
  }; 


  
  return (
    <React.Fragment>
      <div className="messengers">
        {
          filteredMessengers.map((messenger) => (           
            <Button
              key={messenger.title}
              className="messenger"
              onClick={() => messenger.title === "Phone"    ? showContactPhone    (messenger.value) :
                             messenger.title === "Email"    ? showContactEmail    (messenger.value) :
                             messenger.title === "WhatsApp" ? showContactWhatsApp (messenger.value) :
                             messenger.title === "Instagram5" ? showContactInstagram (messenger.value) :
                             showContact(messenger.value)}
            >
              <Icon type={messenger.icon} />
            </Button>
          ))
        }
      </div>
    </React.Fragment>
  );
};

export default Messengers;
