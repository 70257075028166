import React from 'react';
import { handleActions } from 'redux-actions';

import Theme from 'utils/theme';
import Background from 'utils/background';
import ButtonColor from 'utils/buttonColor';

import {
  LOADING_ERROR,
  SET_DATA,
  UPDATE_CONFIG_DATA,
  LANG_SETTINGS,
  CACHE_DATA,
  CATALOG_SEARCH,
  CATALOG_FILTER,
  CATALOG_FILTER_HEADER,

  CATALOG_TABLE_FILTER,
  CATALOG_TABLE_FILTER_HEADER,

  CATALOG_DRINK_FILTER,
  CATALOG_DRINK_FILTER_HEADER,

  CATALOG_ORDER,
  CATALOG_ORDER_CLEAR,
  ORDER_TABLES,
  ORDER_TABLES_CLEAR,
  CATALOG_TABLE_ORDER,
  CATALOG_TABLE_ORDER_CLEAR
} from 'constants/actions';

import {
  CATALOG_HEADER  
} from 'constants/catalogTypes';

import { translatedPropertyName, supportedLanguages } from 'utils/translation';

const defaultTheme = new Theme({ name: 'Default' });
const initialState = {
  error: null,
  data: null,
  langApp: '',
  langSelected: '',
  themes: [defaultTheme],
  instagramFeeds: {},
  currentTheme: defaultTheme,
  backgrounds: [],
  buttonColors: [],
  catalogDrinks: [],
  catalogTables: [],
  storiesDrinks: [],
  storiesTables: [], 
  config: {},
  account: {},
  storyGuid : null,
  storyDrinkGuid : null,
  storyTableGuid : null,
  headerGuid: null,
  headerTableGuid: null,
  search: null,
  headerDrinkGuid: null,
  dataFiltered: null,
  storyDrinkGuidSelected: null,
  storyGuidSelected: null,
  storyTableGuidSelected: null,
  order: {},
  orderTables: {},
  playingGuid: null,
  stoppingGuid: null,
  isSubscriber: false
};

const reducerPwa = handleActions({
  [LOADING_ERROR]: (state, { error }) => ({
    ...state,
    error
  }),

  [SET_DATA]: (state, {
    themes,
    backgrounds,
    buttonColors,
    config,
    account,
    data,
    isSubscriber
  }) => {
    const selectedTheme = themes.find((theme) => data.settings.theme === theme.name)
      || (themes[0] && { ...themes[0] })
      || { ...state.themes[0] };

    let currentTheme = new Theme(selectedTheme);

    if (data.settings.background) {
      const selectedBakground = backgrounds.find((background) => data.settings.background === background.name);
      if (selectedBakground)
        currentTheme = new Theme({ ...currentTheme, background: selectedBakground });
    }

    if (data.settings.color) {
      const selectedButtonColor = buttonColors.find((buttonColor) => data.settings.color === buttonColor.name);
      if (selectedButtonColor)
        currentTheme = new Theme({ ...currentTheme, button: selectedButtonColor });
    }

    let headerGuid = null;    
    let storyGuid = null;
    if (data.catalogItems) {
      for (let i = data.catalogItems.length - 1; i >= 0; i--) {
        let item = data.catalogItems[i];
        if (item.type === CATALOG_HEADER) {
          headerGuid = item.guid;
          storyGuid = item.storyGuid;
        } else if (item.storyGuid === storyGuid) {
          item.headerGuid = headerGuid;
        }
      }
    }    

    
    return {
      ...state,
      themes: themes.map((theme) => new Theme(theme)),
      backgrounds: backgrounds.map((background) => new Background(background)),
      buttonColors: buttonColors.map((buttonColor) => new ButtonColor(buttonColor)),
      config,
      account,
      data: {
        ...data,
      },
      currentTheme,
      error: null,
      isSubscriber
    };
  },

  [UPDATE_CONFIG_DATA]: (state, { title, description }) => ({
    ...state,
    data: {
      ...state.data,
      title,
      description
    }
  }),

  [LANG_SETTINGS] : (state, { langApp} ) => {
    return {
      ...state,
      langApp,
      langSelected: langApp,
    }
  },

  [CACHE_DATA] : (state, { cached }) => {
    const { blocks, stories, stories2Items, stories3Items, stories4Items, stories5Items, 
      storiesBottles, storiesCakes, storiesCigarettas, storiesDishes, storiesDrinks, 
      storiesOffers, storiesSnacks, storiesSoftDrinks, storiesTables, 
      catalogItems, catalog2Items, catalog3Items, catalog4Items, catalog5Items, 
      catalogBottles, catalogCakes, catalogCigarettas, catalogDishes, catalogDrinks, 
      catalogOffers, catalogSnacks, catalogSoftDrinks, catalogTables, 
      avatars, googleMaps } = state.data;
    
      blocks.forEach(b => {
        if (b.image && cached[b.image]) {
          b.image = cached[b.image];
        }
      });
      
      storiesTables.forEach(st => {
        if (st.image && cached[st.image]) {
          st.image = cached[st.image];
        }
      }); 
      
      storiesSoftDrinks.forEach(sa => {
        if (sa.image && cached[sa.image]) {
          sa.image = cached[sa.image];
        }
      });      
      
      storiesSnacks.forEach(ss => {
        if (ss.image && cached[ss.image]) {
          ss.image = cached[ss.image];
        }
      });
      
      storiesOffers.forEach(so => {
        if (so.image && cached[so.image]) {
          so.image = cached[so.image];
        }
      });
      
      storiesDrinks.forEach(sr => {
        if (sr.image && cached[sr.image]) {
          sr.image = cached[sr.image];
        }
      });
      
      storiesDishes.forEach(sd => {
        if (sd.image && cached[sd.image]) {
          sd.image = cached[sd.image];
        }
      });
      
      storiesCigarettas.forEach(sc => {
        if (sc.image && cached[sc.image]) {
          sc.image = cached[sc.image];
        }
      });
      
      storiesCakes.forEach(sk => {
        if (sk.image && cached[sk.image]) {
          sk.image = cached[sk.image];
        }
      });
      
      storiesBottles.forEach(sb => {
        if (sb.image && cached[sb.image]) {
          sb.image = cached[sb.image];
        }
      });
      
      stories5Items.forEach(s5 => {
        if (s5.image && cached[s5.image]) {
          s5.image = cached[s5.image];
        }
      });
      
      stories4Items.forEach(s4 => {
        if (s4.image && cached[s4.image]) {
          s4.image = cached[s4.image];
        }
      });
      
      stories3Items.forEach(s3 => {
        if (s3.image && cached[s3.image]) {
          s3.image = cached[s3.image];
        }
      });
      
      stories2Items.forEach(s2 => {
        if (s2.image && cached[s2.image]) {
          s2.image = cached[s2.image];
        }
      }); 
  
      stories.forEach(s => {
        if (s.image && cached[s.image]) {
          s.image = cached[s.image];
        }
      });  
  
      catalogTables.forEach(ct => {
        if (ct.image && cached[ct.image]) {
          ct.image = cached[ct.image];
        }
      });
      
      catalogSoftDrinks.forEach(ca => {
        if (ca.image && cached[ca.image]) {
          ca.image = cached[ca.image];
        }
      });
      
      catalogSnacks.forEach(cs => {
        if (cs.image && cached[cs.image]) {
          cs.image = cached[cs.image];
        }
      });
      
      catalogOffers.forEach(co => {
        if (co.image && cached[co.image]) {
          co.image = cached[co.image];
        }
      });
      
      catalogDrinks.forEach(cr => {
        if (cr.image && cached[cr.image]) {
          cr.image = cached[cr.image];
        }
      });
      
      catalogDishes.forEach(cd => {
        if (cd.image && cached[cd.image]) {
          cd.image = cached[cd.image];
        }
      });
      
      catalogCigarettas.forEach(cc => {
        if (cc.image && cached[cc.image]) {
          cc.image = cached[cc.image];
        }
      });
      
      catalogCakes.forEach(ck => {
        if (ck.image && cached[ck.image]) {
          ck.image = cached[ck.image];
        }
      });
      
      catalogBottles.forEach(cb => {
        if (cb.image && cached[cb.image]) {
          cb.image = cached[cb.image];
        }
      });
      
      catalog5Items.forEach(c5 => {
      if (c5.image && cached[c5.image]) {
        c5.image = cached[c5.image];
      }
    });
    
    catalog4Items.forEach(c4 => {
      if (c4.image && cached[c4.image]) {
        c4.image = cached[c4.image];
      }
    });
    
    catalog3Items.forEach(c3 => {
      if (c3.image && cached[c3.image]) {
        c3.image = cached[c3.image];
      }
    });

    catalog2Items.forEach(c2 => {
      if (c2.image && cached[c2.image]) {
        c2.image = cached[c2.image];
      }
    });

    catalogItems.forEach(ci => {
      if (ci.image && cached[ci.image]) {
        ci.image = cached[ci.image];
      }
    });

    googleMaps.forEach(g => {
      if (g.image && cached[g.image]) {
        g.image = cached[g.image];
      }
    });
    
    avatars.forEach(a => {
      if (a.image && cached[a.image]) {
        a.image = cached[a.image];
      }
    });
    
    
    return {
      ...state,
      data: {
        ...state.data,
        stories, stories2Items, stories3Items, stories4Items, stories5Items, 
      storiesBottles, storiesCakes, storiesCigarettas, storiesDishes, storiesDrinks, 
      storiesOffers, storiesSnacks, storiesSoftDrinks, storiesTables, 
      catalogItems, catalog2Items, catalog3Items, catalog4Items, catalog5Items, 
      catalogBottles, catalogCakes, catalogCigarettas, catalogDishes, catalogDrinks, 
      catalogOffers, catalogSnacks, catalogSoftDrinks, catalogTables, 
      avatars, googleMaps
      }
    }
  },

  

  [CATALOG_FILTER] : (state, { storyGuid } ) => {
    return {
      ...state,
      storyGuid,
      storyGuidSelected: storyGuid,
    }
  },

  [CATALOG_DRINK_FILTER] : (state, { storyDrinkGuid } ) => {
    return {
      ...state,
      storyDrinkGuid,
      storyDrinkGuidSelected: storyDrinkGuid,
    }
  },

  [CATALOG_TABLE_FILTER] : (state, { storyTableGuid } ) => {
    return {
      ...state,
      storyTableGuid,
      storyTableGuidSelected: storyTableGuid,
    }
  },

  [CATALOG_SEARCH] : (state, { search } ) => {
    const parts = (search?? "").toLowerCase().split(" ").filter(p => p.trim().length > 2)
    if (parts.length > 0) {
      const { catalogItems, stories } = state.data;
      const filteredCatalogItems = catalogItems.filter(item => item.price).filter(item =>
        parts.filter(p => (item.text ?? "").toLowerCase().includes(p) || 
          (item.textAlt ?? "").toLowerCase().includes(p) ||
          (item.description ?? "").toLowerCase().includes(p) || 
          (item.number ?? "").toLowerCase().includes(p) ||
          supportedLanguages.filter(lang => 
            (item[translatedPropertyName("text", lang)] ?? "").toLowerCase().includes(p) ||
            (item[translatedPropertyName("textAlt", lang)] ?? "").toLowerCase().includes(p) ||
            (item[translatedPropertyName("description", lang)] ?? "").toLowerCase().includes(p))
            .length > 0
          ).length === parts.length)
      const filteredStories = stories.filter(s =>
        filteredCatalogItems.filter(item => item.storyGuid === s.guid).length > 0);
      
      const storyGuidSelected = state.storyGuidSelected && 
        filteredStories.filter(s => s.guid === state.storyGuidSelected).length > 0 ? state.storyGuidSelected : 
        filteredStories[0]?.guid;

      return {
        ...state,
        dataFiltered: {
          stories: filteredStories,
          catalogItems: filteredCatalogItems
        },
        storyGuidSelected,
        search
      }
    } else {
      return {
        ...state,
        dataFiltered: null,
        storyGuidSelected: state.storyGuid,
        search
      }
    }
  },
 

  [CATALOG_FILTER_HEADER] : (state, { headerGuid }) => {
    return {
      ...state,
      headerGuid
    }
  },

  [CATALOG_DRINK_FILTER_HEADER] : (state, { headerDrinkGuid }) => {
    return {
      ...state,
      headerDrinkGuid
    }
  },

  [CATALOG_TABLE_FILTER_HEADER] : (state, { headerTableGuid }) => {
    return {
      ...state,
      headerTableGuid
    }
  },

  [CATALOG_ORDER] : (state, { guid, count, sum, currency, nameEmptyItem, price }) => {
    return {
      ...state,
      order: {
        ...state.order,
        [guid]: { count, sum, currency, nameEmptyItem, price } 
      }
    }
  },

  [ORDER_TABLES] : (state, { id, status, tabelNumber }) => {
    return {
      ...state,
      orderTables: {
        ...state.orderTables,
        [id]: { status, tabelNumber } 
      }
    }
  },

  [CATALOG_TABLE_ORDER] : (state, { guid, count, sum, currency }) => {
    return {
      ...state,
      order: {
        ...state.order,
        [guid]: { count, sum, currency } 
      }
    }
  },
  

  [CATALOG_ORDER_CLEAR] : (state) => {
    return {
      ...state,
      order: {}
    }
  },

  [ORDER_TABLES_CLEAR] : (state) => {
    return {
      ...state,
      orderTables: {}
    }
  },

    [CATALOG_TABLE_ORDER_CLEAR] : (state) => {
      return {
        ...state,
        order: {}
    }
  }

  
  
}, initialState);

export default reducerPwa;
