import React from 'react';

import PropTypes from 'prop-types';
import classnames from 'classnames';
import { select } from 'redux-saga/effects';
import menuOnline from 'images/empty.png';
import Button from 'components/common/Button';
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';
import { __ } from 'utils/translation';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './index.scss';
import 'components/Stories/Story/index.scss';

import { translatedProperty } from 'utils/translation';


const Story = (props) => {

    const {
        image,
        onClick,
        className,
        selected,
        scrollPosition,
        index
    } = props;
    const title = translatedProperty(props, "title")

    const story = (
        <>
            <div className={classnames([className])} onClick={onClick} >
                <div className="story-picture">
                    <div className="story-picture__box"
                        style={{ backgroundImage: `URL(${menuOnline})` }}
                    >
                        <LazyLoadImage
                            src={image}
                            alt={title}
                            height={250}
                            width={250}
                            effect="blur"
                            scrollPosition={scrollPosition}
                        />
                        {index === 0 && <div className="story-clickme">{__("Нажми")}</div>}
                    </div>
                    {selected ? 
                        <>
                            <Button onClick={onClick} className="story-picture-title-selected">{title}</Button>
                            
                        </>
                        :
                        <>
                            <Button onClick={onClick} className="story-picture-title">{title}</Button>
                        </>
                    }
                    
                </div>
                {selected ? 
                        <>
                            <div className="story-picture-title-selected-triangle"/>
                            
                        </>
                        :
                        <>
                            <div className="story-picture-title-selected-notriangle"/>
                        </>
                    }
            </div>
        </>
    );
    return story;
}

Story.propTypes = {
    title: PropTypes.string,
    image: PropTypes.string,

};

Story.defaultProps = {
    title: '',
    image: undefined,

};

export default Story;
