import React, { useState, useEffect } from 'react';
import { useDispatch,useSelector  } from 'react-redux';
import { __ } from 'utils/translation';
import Button from 'components/common/Button';
import Slider from 'components/common/Slider';
import langRu from 'images/russia.jpg';
import langDe from 'images/germany.jpg';
import langEn from 'images/usa.jpg';
import langFr from 'images/france.jpg';
import langIt from 'images/italy.jpg';
import langEs from 'images/spain.jpg';
import langZh from 'images/china.jpg';
import { LANG_SETTINGS } from 'constants/actions';

import './index.scss';



const Lang = () => {
    const dispatch = useDispatch();
    const [changeLang, setChangeLang] = useState(false);
    const [choiceLanguage, setChoiceLanguage] = useState('');
    const [settingsOpened, setSettingsOpened] = useState(false);
    const language = (window.navigator.userLanguage || window.navigator.language)?.substring(0, 2)?.toLowerCase();

    const { langSelected } = useSelector((state) => state.config);
    
    useEffect(() => {
        dispatch({ type: LANG_SETTINGS, langApp: language });
    }, []);

    const changeLanguage = () => {
        setChangeLang (true);
        setSettingsOpened (true);
    };

    const closeSettings = () => {
        setSettingsOpened (false);
    };

    const choice = (lang) => {
        dispatch({ type: LANG_SETTINGS, langApp: lang });
        setChoiceLanguage(lang);
    }

    return (
        <React.Fragment>
            {!changeLang ? 
                <>
                    {language === 'ru' && <Button name="change language to russan" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langRu})` }} />}
                    {language === 'es' && <Button name="change language to espanol" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langEs})` }} />}
                    {language === 'fr' && <Button name="change language to france" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langFr})` }} />}
                    {language === 'it' && <Button name="change language to italian" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langIt})` }} />}
                    {language === 'de' && <Button name="change language to aleman" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langDe})` }} />}
                    {language === 'zh' && <Button name="change language to china" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langZh})` }} />}
                    {language === 'en' && <Button name="change language to english" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langEn})` }} />}
                    {(language !== 'zh' && language !== 'de' && language !== 'it' && language !== 'fr'&& language !== 'es'&& language !== 'ru') && 
                        <Button name="change language to english" noStyle onClick={changeLanguage}className="lang-button" style={{ backgroundImage: `URL(${langEn})` }} />}
                </> 
                :
                <>
                    {choiceLanguage === 'ru' && <Button name="change language to russan" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langRu})` }} />}
                    {choiceLanguage === 'es' && <Button name="change language to espanol" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langEs})` }} />}
                    {choiceLanguage === 'fr' && <Button name="change language to france" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langFr})` }} />}
                    {choiceLanguage === 'it' && <Button name="change language to italian" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langIt})` }} />}
                    {choiceLanguage === 'de' && <Button name="change language to aleman" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langDe})` }} />}
                    {choiceLanguage === 'zh' && <Button name="change language to china" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langZh})` }} />}
                    {choiceLanguage === 'en' && <Button name="change language to english" noStyle onClick={changeLanguage} className="lang-button" style={{ backgroundImage: `URL(${langEn})` }} />}                    
                </> 
            }
            <Slider
                opened={settingsOpened}
                onClose={choiceLanguage !=='' ? closeSettings : null}
                onSubmit={choiceLanguage !=='' ? closeSettings : null}
                submitTitle={__("ЗАКРЫТЬ")?.toUpperCase()}
                title={__("Выберите язык приложения")}
                subtitle={choiceLanguage !=='' ? (__("Вы изменили язык приложения на") +  " " + choiceLanguage) : null}

            >
                <div className='lang-scroll-box'>
                    <Button name="change language to russan" noStyle onClick={()=> choice('ru')} className={langSelected === 'ru' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langRu})` }} />
                    <Button name="change language to espanol" noStyle onClick={()=> choice('es')} className={langSelected === 'es' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langEs})` }} />
                    <Button name="change language to france" noStyle onClick={()=> choice('fr')} className={langSelected === 'fr' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langFr})` }} />
                    <Button name="change language to italian" noStyle onClick={()=> choice('it')} className={langSelected === 'it' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langIt})` }} />
                    <Button name="change language to aleman" noStyle onClick={()=> choice('de')} className={langSelected === 'de' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langDe})` }} />
                    <Button name="change language to china" noStyle onClick={()=> choice('zh')} className={langSelected === 'zh' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langZh})` }} />
                    <Button name="change language to english" noStyle onClick={()=> choice('en')} className={langSelected === 'en' ? "lang-button-selected" :  "lang-button" } style={{ backgroundImage: `URL(${langEn})` }} />
                </div>
                
            </Slider>
        </React.Fragment >
    );
};

export default Lang;