import React from 'react';

import './index.scss';

const Loading = () => (
  <div className="spinner-body">
    <div className="spinner">
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </div>
  </div>
);

export default Loading;
