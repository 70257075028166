import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { __ } from 'utils/translation';
import Textarea from 'components/common/Textarea';
import Input from 'components/common/Input';
import Button from 'components/common/Button';
import Icon from 'components/common/Icon';
import { getSearchParams } from 'utils/url';
import { CATALOG_ORDER } from 'constants/actions';
import './index.scss';

const CatalogEmptyItem = () => {

    const [nameEmptyItem, setNameEmptyItem] = useState('');
    const [price, setPrice] = useState(0);
    const [currency, setCurrency] = useState('€');
    const [count, setCount] = useState(0);
    const [guid, setGuid] = useState(0);
    const [sum, setSum] = useState(0);
    const [newItem, setNewItem] = useState(true);
    const dispatch = useDispatch();
    var params = getSearchParams(window.location.search);
    const { lang } = useSelector((state) => state.config.config);
    const [orderProps, setOrderProps] = useState({});
    const [visualProps, setVisualProps] = useState([]);

    useEffect(() => {
        const isPwa = params['pwa'] === "" || params['pwa'];

        const ignoredParams = ["pwa", "demo"];
        const props = {}
        const vprops = [];
        props[__(isPwa ? "Отправлено из установленного приложения" : "Отправлено с сайта", lang ?? "es")] = "";
        for (var propName in params) {
            if (propName && !ignoredParams.includes(propName)) {
                props[propName] = params[propName];
                vprops.push({ key: propName, value: params[propName] });
            }
        }
        setOrderProps(props);
        setVisualProps(vprops);
    }, []);

    useEffect(() => {
        let timeInMs = Date.now();
        setGuid(timeInMs);
        setPrice('');
        setCount(0);
        setNameEmptyItem('');
        setSum(0)
        console.log("setGuid new", guid)
        console.log("count", count)
    }, [newItem]);

    let isIAmWaiter = false;
    if (params['iamwaiter'] === "" || params['iamwaiter']) {
        isIAmWaiter = true;
    };

    const handlePlus = (e) => {
        e.stopPropagation();
        setCount(count + 1);
        setSum(price * (count + 1));
        dispatch({
            type: CATALOG_ORDER, guid, count: count + 1,
            sum: sum + parseFloat(price), currency, nameEmptyItem, price
        });
    };
    const handleMinus = (e) => {
        e.stopPropagation();
        if (count === 0) {
            return;
        };
        setCount(count - 1);
        setSum(price * (count - 1));
        dispatch({
            type: CATALOG_ORDER, guid, count: count - 1,
            sum: sum - parseFloat(price), currency, nameEmptyItem, price
        });
    };



    const handleValueEmptyItem = useCallback((value) => {
        setNameEmptyItem(value);
    }, [setNameEmptyItem]);

    const handlePrice = useCallback((value) => {
        setPrice(value);
    }, [setPrice]);

    const handleCurrency = useCallback((value) => {
        setCurrency(value);
    }, [setCurrency]);

    return (

        <div className="catalog-empty-item">
            {isIAmWaiter ?
                <>
                    <div className="catalog-empty-item-title">{__("Быстрое создание заказа")}</div>
                    
                    {newItem &&
                        <>
                            <Textarea className="catalog-empty-item-field-name"
                                value={nameEmptyItem}
                                name="fieldTextCaltalogEmptyItem"
                                type="text"
                                placeholder={__("Название или артикул (минимум 3 знака)")}
                                onChange={handleValueEmptyItem}
                            />
                            <div className="catalog-empty-item-flexrow-box">
                                <Input className="catalog-empty-item-field-price"
                                    value={price}
                                    name="fieldPriceCaltalogEmptyItem"
                                    type="number"
                                    placeholder={__("Стоимость за 1 ед.")}
                                    onChange={handlePrice}
                                />
                                <Input className="catalog-empty-item-field-currency"
                                    value={currency}
                                    name="fieldCurrencyCaltalogEmptyItem"
                                    type="text"
                                    placeholder="€"
                                    onChange={handleCurrency}
                                />
                            </div>
                            {price > 0 &&
                                <>
                                    <div className="catalogItem-order-box">
                                        <Button className="catalogItem-order-botton" noStyled
                                            onClick={handleMinus} >{__("Убрать")}</Button>
                                        <div className="catalogItem-order-box-quantity">{count}</div>
                                        <Button className="catalogItem-order-botton" noStyled
                                            onClick={handlePlus} >{__("Добавить")}</Button>
                                    </div>
                                    <br />
                                    {newItem && nameEmptyItem.length > 2 && count > 0 && <Button className="catalogItem-order-botton" noStyled
                                        onClick={() => newItem ? setNewItem(false) : setNewItem(true)} >{__("Добавить")}</Button>}
                                </>
                            }
                        </>}
                    <br />
                    {!newItem && <Button className="catalogItem-order-botton" noStyled
                        onClick={() => newItem ? setNewItem(false) : setNewItem(true)} >{__("Добавить новое блюдо")}</Button>}
                </> : null}
        </div>

    );

};
export default CatalogEmptyItem;

