import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { uuid } from 'uuidv4';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import { useCookies } from 'react-cookie';
import addedMenu from 'images/addedmenu.jpg';
import Slider from 'components/common/Slider';
import Button from 'components/common/Button';
import StoryTable from 'components/StoriesTables/StoryTable';
import SearchComponent from 'components/SearchComponent';
import { __ } from 'utils/translation';

import { scrollTo } from 'utils/scrolling';
import { SCROLL_CATALOG_ID } from 'constants/scrolls';
import { CATALOG_TABLE_FILTER, CATALOG_SEARCH } from 'constants/actions';

import './index.scss';
import 'components/StoriesTables/index.scss';

const emptySettings = {
  guid: null,
  type: 'preview-text',
  linkUrl: '',
  description: '',
  image: '',
};

const StoriesTables = ({ data, profile, scrollPosition }) => {
  const [settingsOpened, setSettingsOpened] = useState(null);
  const [storyTableData, setStoryTableData] = useState(emptySettings);
  const [cookies] = useCookies();

  const closeStoriesTablesSettings = () => {
    setSettingsOpened(null);
  };


  const { active } = useSelector((state) => state.config.account);

  const { storiesTables = [] } = useSelector((state) => state.config.data);
  const { catalogTables = [] } = useSelector((state) => state.config.data);
  const { blocks } = useSelector((state) => state.config.data);
  const { storyTableGuid, storyTableGuidSelected, search } = useSelector((state) => state.config);

  const dispatch = useDispatch();



  const handleSearch = (value) => {
    dispatch({ type: CATALOG_SEARCH, search: value });
  }

  var inviteId = cookies[profile];
  if (inviteId === "undefined") {
    inviteId = null;
  }

  useEffect(() => {
    const currentStoryTable = storiesTables.find((storyTable) => storyTable.guid === settingsOpened);
    const settings = currentStoryTable || { ...emptySettings };
    if (!settings.guid)
      settings.guid = uuid();
    setStoryTableData({ ...settings });
  }, [settingsOpened, storiesTables]);

  const handleStoryTableClick = (storyTableId) => {
      dispatch({ type: CATALOG_TABLE_FILTER, storyTableGuid: storyTableGuid !== storyTableId ? storyTableId : null });
    };
  

  const placeholder = __("Введите номер столика");

  storiesTables.sort((a, b) => b.order - a.order);
  return (
    <React.Fragment>
      <div className="stories">
        {storiesTables.length > 1 &&
          <>
          <br/><br/><br/>
            {/* <SearchComponent search={search} placeholder={placeholder} onSearch={handleSearch} /> */}
            <div className="stories-table-picker">
              {data.map((storyTable) =>
                <StoryTable className={classnames([`stories-table-picker-item`])}
                  onClick={() => handleStoryTableClick(storyTable.guid)}
                  key={storyTable.guid} {...storyTable}
                  selected={storyTableGuidSelected === storyTable.guid}
                  scrollPosition={scrollPosition} />)
              }
            </div>
          </>
        }
      </div>

      {/* <Slider
        opened={settingsOpened}
        onClose={closeStoriesSettings}
        onSubmit={closeStoriesSettings}
      >
        <StoryTableSettings {...storyTableData} />
      </Slider> */}

    </React.Fragment>
  );
};

StoriesTables.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({}))
};

StoriesTables.defaultProps = {
  data: []
};

export default trackWindowScroll(StoriesTables);
