import React, { useEffect } from 'react';
import history from 'utils/history';

const Admin = () => {
  const linkUrl = "https://dash.maps.menu";

  useEffect(() => {
    window.open(linkUrl, "_self");
  }, []);


  return (
    <div/>
  );
};
  
export default Admin;
