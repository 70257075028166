import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { __ } from 'utils/translation';
import Button from 'components/common/Button';
import imageEmpty from 'images/no-image.jpg';
import './index.scss';
import { CATALOG_ORDER, CATALOG_FILTER_HEADER } from 'constants/actions';
import { translatedProperty } from 'utils/translation';

const CatalogItemTablet = ({
  text, textEn, textRu, textEs, textIt, textDe, textFr, textZh, textAlt, textAltEn, textAltRu, textAltEs, textAltIt, textAltDe, textAltFr, textAltZh,
  onClick, guid, animation, image, price, currency, number, type, className, technical, index, reviews,  
}) => {
  const dispatch = useDispatch();
  const { count, sum } = useSelector((state) => state.config.order[guid] ?? { count: 0, sum: 0 });
  const { headerGuid } = useSelector((state) => state.config);


  const translatedText = translatedProperty({ text, textEn, textEs, textRu, textDe, textFr, textIt, textZh }, "text");
  const translatedTextAlt = translatedProperty({ textAlt, textAltEn, textAltEs, textAltRu, textAltDe, textAltFr, textAltIt, textAltZh }, "textAlt");

  const handleHeaderClick = () => {
    dispatch({ type: CATALOG_FILTER_HEADER, headerGuid: headerGuid === guid ? null : guid });
  }


  const nameEmptyItem = "";
  const handlePlus = (e) => {
    e.stopPropagation();
    dispatch({
      type: CATALOG_ORDER, guid, count: count + 1,
      sum: sum + parseFloat(price), currency, nameEmptyItem
    });
  };
  const handleMinus = (e) => {
    e.stopPropagation();
    if (count === 0) {
      return;
    }
    dispatch({
      type: CATALOG_ORDER, guid, count: count - 1,
      sum: sum - parseFloat(price), currency, nameEmptyItem
    });
  };

  const handleChecked = (e) => {
    if (count === 0) {
      handlePlus(e);
    } else {
      handleMinus(e);
    }
  }


  const sumValue = count !== 0 ? sum.toFixed(2) :
    parseFloat(price).toFixed(2);


  // const reviewImage = (reviews?.filter(r => r.isActive) ?? []).reverse().filter(r => r.image)[0]?.image;
  // const imageCard = image ?? reviewImage;

  const imageCard = image ?? imageEmpty

  const catalogItem = (
        <div className='catalogItemTablet' key={guid} onClick={onClick}
        >
          <img src={image || imageEmpty} alt={translatedText} />
          
          {(translatedText && !translatedTextAlt) && (price || number) && (
            <div className="catalogItemTablet-preorder-flex-column">
              <div className="catalogItem-price-empty"></div>
              <div className="catalogItemTablet-title">{translatedText}</div>
              <div className="catalogItem-price-empty"></div>
            </div>
          )}

          {(translatedText && translatedTextAlt) && (price || number) && (
            <div className="catalogItemTablet-preorder-flex-column">
              <div className="catalogItem-price-empty"></div>
              <div className="catalogItemTablet-title-with-textEn">{translatedText}</div>
              <div className="catalogItemTablet-text-en">{translatedTextAlt}</div>
            </div>
          )}

          <div className="catalogItemTablets-price">•&nbsp;{price}&nbsp;{ currency || ""}</div>
        </div>
      );

      if ((price || number) && index === 0)
        return (
            <Button className="button-in-catalogItemTablet " isPulse={true} technical={technical}>
              <div className="story-clickme">{__("Нажми")}</div>
              {catalogItem}
            </Button>
        );

      if ((price || number) && index !== 0)
        return (
            <Button className="button-in-catalogItemTablet " isPulse={animation} technical={technical}>
              {catalogItem}
            </Button>
        );
        return null;
};

CatalogItemTablet.propTypes = {
  guid: PropTypes.string,
  animation: PropTypes.bool,
  image: PropTypes.string,
  text: PropTypes.string,
  textEn: PropTypes.string,
  price: PropTypes.number,
  currency: PropTypes.string,
  number: PropTypes.number,
  outOfStock: PropTypes.bool,
  type: PropTypes.string,
  technical: PropTypes.bool,
  refObj: PropTypes.object
};

CatalogItemTablet.defaultProps = {
  animation: false,
  image: undefined,
  text: '',
  textEn: '',
  price: undefined,
  currency: '',
  number: undefined,
  outOfStock: false,
  type: undefined,
  technical: false,
  refObj: undefined
};

export default CatalogItemTablet;
