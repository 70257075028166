import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import copy from 'clipboard-copy';
import classnames from 'classnames';
import Slider from 'components/common/Slider';
import Button from 'components/common/Button';
import Icon from 'components/common/Icon';
import { useReactPWAInstall } from 'components/PwaInstall/component.js';
import PwaInstall from "components/PwaInstall";
import StartPwaInstallIos from 'components/StartPwaInstallIos';
import logo512 from 'images/maps_menu_logo.jpg';
import raiting from 'images/raiting.png';
import { IonIcon } from '@ionic/react';
import { shareOutline, star, starHalf } from 'ionicons/icons';
import { isIDevice, isIOsSafari, isNativePwa } from 'utils/browser';
import AvatarBase from 'components/common/AvatarBase';
import { __ } from 'utils/translation';
import { getSearchString, getSearchParams } from 'utils/url';

import './index.scss';

const Pwaupbanner = ({
  profile, data
}) => {
  const { title, description, name, avatarPreview, url } = useSelector((state) => state.config.data);
  const [opened, setOpened] = useState(false);
  const { supported, isInstalled } = useReactPWAInstall();
  const [showPwaupbanner, setShowPwaupbanner] = useState(false);
  const params = getSearchParams(window.location.search);
  const { messengers } = useSelector((state) => state.config.data);
  const whatsappMessenger = messengers.filter(e => e.title === "WhatsApp")[0];
  const hasWhatsApp = whatsappMessenger?.value;

  let isNotNeedBanner = false;
  if (params['whatsapp'] === "" || params['whatsapp']) {
    isNotNeedBanner = true;
  };
  const urlWhatsapp = `${url}?whatsapp`
  const linkWhatsappSaveLink = `https://wa.me/${hasWhatsApp.replace(/[^0-9]/g, '')}?text=${__("Хочу сохранить эту ссылку для будущего использования")}: ${name}, ${title}, ${urlWhatsapp}`;
  

  useEffect(() => {   
    const needBanner = true;
    setShowPwaupbanner(needBanner);
  }, []);


  const handleClose = (e) => {
    setShowPwaupbanner(false);
    e.stopPropagation();
  }

  const handleInstall = () => {
    setOpened(true);
  }


  if (isNotNeedBanner || !hasWhatsApp)
    return null;

  return (
    <div>
      <div className={classnames(['pwaupbanner', { hidden: !showPwaupbanner }])}>
        <div className="box-left">
          <Button
            noStyled
            isInline
            className="box-left__close"
            onClick={handleClose}
          >
            <Icon type="timesCircle" />
          </Button>
        </div>
        <div className="pwaupbanner-heder-avatar-box" >
          <AvatarBase avatar={data[0].image} avatarPreview={data[0].image} avatarDefault={logo512} wrapperImageClass="pwaupbanner-heder-avatar" />
        </div>
        <div className="titleBox">
          <div className="firstBox">{name}</div>
          <div className="secondBox">{title}</div>
          <div className="thirdBox" style={{ backgroundImage: `URL(${raiting})` }} />
          <div className="fourthBox">{__("без регистрации, бесплатно, мгновенно")}</div>
        </div>
        <div className="box-right">
          <div className="install" onClick={handleInstall}>
            {__("Установить")}
          </div>
        </div>
      </div>



      <Slider
        opened={opened}
        onClose={() => setOpened(false)}
        title={__("Сохраните наше приложение в своем Whatsapp")}
        subtitle=
        {<p>{__("Если вы планируете вновь посетить наш ресторан, вы можете сохранить ссылку в вашем Whatsapp")}</p>}
      >

        <Button className="pwaupbannercall"
          onClick={() => window.open(linkWhatsappSaveLink, "_blank")} >
          {__("СОХРАНИТЬ В WHATSAPP")}
        </Button>
        <div className="pwaupbannerwhytitle">{__("КАК ЭТО РАБОТАЕТ?")}</div>
        <div className="pwaupbannerwhybody">{__("Вы нажимаете кнопку СОХРАНИТЬ В WHATSAPP и мы создаем сообщение в наш Whatsapp от вашего имени. В ответ на это сообщение мы отправляем вам персональную ссылку. В будущем вы сможете найти этот чат, чтобы открыть вновь наш сайт. Вы можете заблокировать наш контакт в любое время.")}</div>

        <div className="pwaupbannerempty"></div>
      </Slider>
    </div>);
};

export default Pwaupbanner;