import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { __ } from 'utils/translation';
import addedAvatar from 'images/unnamed.jpg';
import './index.scss';



const AvatarsApp = () => {
    var referrer = document.referrer;
  
    console.log("document.referrer", document.referrer);
    
    return (
        <React.Fragment>
            <div className="avatar-maps-username-box">
               
                        <div className="avatar-maps" onClick={() => window.open(referrer, "_self")} style={{ backgroundImage: `URL(${addedAvatar})` }} />
                        <h1 className="main-page__page1__h3"
                            onClick={() => window.open(referrer, "_self")}>
                            {__("APP официанта")}
                        </h1>
                    </div>

        </React.Fragment >
    );
};

AvatarsApp.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({}))
};

AvatarsApp.defaultProps = {
    data: []
};

export default AvatarsApp;