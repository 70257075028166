import React from 'react';
import { handleActions } from 'redux-actions';

import Theme from 'utils/theme';
import Background from 'utils/background';
import ButtonColor from 'utils/buttonColor';

import {
  LOADING_ERROR,
  SET_DATA,
  UPDATE_CONFIG_DATA,
  LANG_SETTINGS,
  CATALOG_SEARCH,
  CATALOG_FILTER,
  CATALOG_FILTER_HEADER,

  CATALOG_TABLE_FILTER,
  CATALOG_TABLE_FILTER_HEADER,

  CATALOG_DRINK_FILTER,
  CATALOG_DRINK_FILTER_HEADER,

  CATALOG_ORDER,
  CATALOG_ORDER_CLEAR,
  ORDER_TABLES,
  ORDER_TABLES_CLEAR,
  CATALOG_TABLE_ORDER,
  CATALOG_TABLE_ORDER_CLEAR
} from 'constants/actions';

import {
  CATALOG_HEADER  
} from 'constants/catalogTypes';

import { translatedPropertyName, supportedLanguages } from 'utils/translation';

const defaultTheme = new Theme({ name: 'Default' });
const initialState = {
  error: null,
  data: null,
  langApp: '',
  themes: [defaultTheme],
  instagramFeeds: {},
  currentTheme: defaultTheme,
  backgrounds: [],
  buttonColors: [],
  catalogDrinks: [],
  catalogTables: [],
  storiesDrinks: [],
  storiesTables: [], 
  config: {},
  account: {},
  storyGuid : null,
  storyDrinkGuid : null,
  storyTableGuid : null,
  headerGuid: null,
  headerTableGuid: null,
  search: null,
  headerDrinkGuid: null,
  dataFiltered: null,
  storyDrinkGuidSelected: null,
  storyGuidSelected: null,
  storyTableGuidSelected: null,
  order: {},
  orderTables: {},
  playingGuid: null,
  stoppingGuid: null,
  isSubscriber: false
};

const reducer = handleActions({
  [LOADING_ERROR]: (state, { error }) => ({
    ...state,
    error
  }),

  [SET_DATA]: (state, {
    themes,
    backgrounds,
    buttonColors,
    config,
    account,
    data,
    isSubscriber
  }) => {
    const selectedTheme = themes.find((theme) => data.settings.theme === theme.name)
      || (themes[0] && { ...themes[0] })
      || { ...state.themes[0] };

    let currentTheme = new Theme(selectedTheme);

    if (data.settings.background) {
      const selectedBakground = backgrounds.find((background) => data.settings.background === background.name);
      if (selectedBakground)
        currentTheme = new Theme({ ...currentTheme, background: selectedBakground });
    }

    if (data.settings.color) {
      const selectedButtonColor = buttonColors.find((buttonColor) => data.settings.color === buttonColor.name);
      if (selectedButtonColor)
        currentTheme = new Theme({ ...currentTheme, button: selectedButtonColor });
    }

    let headerGuid = null;    
    let storyGuid = null;
    if (data.catalogItems) {
      for (let i = data.catalogItems.length - 1; i >= 0; i--) {
        let item = data.catalogItems[i];
        if (item.type === CATALOG_HEADER) {
          headerGuid = item.guid;
          storyGuid = item.storyGuid;
        } else if (item.storyGuid === storyGuid) {
          item.headerGuid = headerGuid;
        }
      }
    }   

    return {
      ...state,
      themes: themes.map((theme) => new Theme(theme)),
      backgrounds: backgrounds.map((background) => new Background(background)),
      buttonColors: buttonColors.map((buttonColor) => new ButtonColor(buttonColor)),
      config,
      account,
      data: {
        ...data,
        // reviews,
        // reviewsTables
      },
      currentTheme,
      error: null,
      isSubscriber
    };
  },

  [UPDATE_CONFIG_DATA]: (state, { title, description }) => ({
    ...state,
    data: {
      ...state.data,
      title,
      description
    }
  }),
  
  [LANG_SETTINGS] : (state, { langApp} ) => {
    return {
      ...state,
      langApp,
      langSelected: langApp,
    }
  },

  [CATALOG_FILTER] : (state, { storyGuid } ) => {
    return {
      ...state,
      storyGuid,
      storyGuidSelected: storyGuid,
    }
  },

  [CATALOG_DRINK_FILTER] : (state, { storyDrinkGuid } ) => {
    return {
      ...state,
      storyDrinkGuid,
      storyDrinkGuidSelected: storyDrinkGuid,
    }
  },

  [CATALOG_TABLE_FILTER] : (state, { storyTableGuid } ) => {
    return {
      ...state,
      storyTableGuid,
      storyTableGuidSelected: storyTableGuid,
    }
  },

  [CATALOG_SEARCH] : (state, { search } ) => {
    const parts = (search?? "").toLowerCase().split(" ").filter(p => p.trim().length > 2)
    if (parts.length > 0) {
      const { catalogItems, stories } = state.data;
      const filteredCatalogItems = catalogItems.filter(item => item.price).filter(item =>
        parts.filter(p => (item.text ?? "").toLowerCase().includes(p) || 
          (item.textAlt ?? "").toLowerCase().includes(p) ||
          (item.description ?? "").toLowerCase().includes(p) || 
          (item.number ?? "").toLowerCase().includes(p) ||
          supportedLanguages.filter(lang => 
            (item[translatedPropertyName("text", lang)] ?? "").toLowerCase().includes(p) ||
            (item[translatedPropertyName("textAlt", lang)] ?? "").toLowerCase().includes(p) ||
            (item[translatedPropertyName("description", lang)] ?? "").toLowerCase().includes(p))
            .length > 0
          ).length === parts.length)
      const filteredStories = stories.filter(s =>
        filteredCatalogItems.filter(item => item.storyGuid === s.guid).length > 0);
      
      const storyGuidSelected = state.storyGuidSelected && 
        filteredStories.filter(s => s.guid === state.storyGuidSelected).length > 0 ? state.storyGuidSelected : 
        filteredStories[0]?.guid;

      return {
        ...state,
        dataFiltered: {
          stories: filteredStories,
          catalogItems: filteredCatalogItems
        },
        storyGuidSelected,
        search
      }
    } else {
      return {
        ...state,
        dataFiltered: null,
        storyGuidSelected: state.storyGuid,
        search
      }
    }
  },
 

  [CATALOG_FILTER_HEADER] : (state, { headerGuid }) => {
    return {
      ...state,
      headerGuid
    }
  },

  [CATALOG_DRINK_FILTER_HEADER] : (state, { headerDrinkGuid }) => {
    return {
      ...state,
      headerDrinkGuid
    }
  },

  [CATALOG_TABLE_FILTER_HEADER] : (state, { headerTableGuid }) => {
    return {
      ...state,
      headerTableGuid
    }
  },

  [CATALOG_ORDER] : (state, { guid, count, sum, currency, nameEmptyItem, price }) => {
    return {
      ...state,
      order: {
        ...state.order,
        [guid]: { count, sum, currency, nameEmptyItem, price } 
      }
    }
  },

  [ORDER_TABLES] : (state, { id, status, tabelNumber }) => {
    return {
      ...state,
      orderTables: {
        ...state.orderTables,
        [id]: { status, tabelNumber } 
      }
    }
  },

  [CATALOG_TABLE_ORDER] : (state, { guid, count, sum, currency }) => {
    return {
      ...state,
      order: {
        ...state.order,
        [guid]: { count, sum, currency } 
      }
    }
  },
  

  [CATALOG_ORDER_CLEAR] : (state) => {
    return {
      ...state,
      order: {}
    }
  },

  [ORDER_TABLES_CLEAR] : (state) => {
    return {
      ...state,
      orderTables: {}
    }
  },

    [CATALOG_TABLE_ORDER_CLEAR] : (state) => {
      return {
        ...state,
        order: {}
    }
  }

  
  
}, initialState);

export default reducer;
