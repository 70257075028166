import React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import Button from 'components/common/Button';
import Icon from 'components/common/Icon';
import { __ } from 'utils/translation';
import './index.scss';

const Reviews = () => {

    const { reviews = [] } = useSelector((state) => state.config.data);

    const urlParametrs = `https://search.google.com/local/writereview?placeid=`;

    const filteredReviews = reviews.filter((review) => review.value !== ''); 
    const showReviews = filteredReviews.length !== 0;
           
    return (
        <React.Fragment>
            {showReviews && <>
                <div className="reviews">
                    {reviews.map((review) => ( 
                        <Button
                            key={review.title}
                            className="review pulse"
                            onClick={() => window.open(urlParametrs + review.value)}
                        >
                            <div className="revies-stars-title">{__("Нажмите, чтобы оставить свой отзыв в GOOGLE MAPS")}
                            </div>
                            <div className="revies-stars-box">
                                <Icon className={classnames(['icon-google-reviews'])} type="star" />
                                <Icon className={classnames(['icon-google-reviews'])} type="star" />
                                <Icon className={classnames(['icon-google-reviews'])} type="star" />
                                <Icon className={classnames(['icon-google-reviews'])} type="star" />
                                <Icon className={classnames(['icon-google-reviews'])} type="star" />
                            </div>
                            <p>{__('Ваше мнение очень важно!!!')}</p>
                        </Button>
                    ))
                    }
                    
                    <p style={{textAlign:"center"}}>{__("Спасибо. Вы помогаете нам быть лучше")}</p>
                </div>
            </>
            }


        </React.Fragment>
    );
};

export default Reviews;
