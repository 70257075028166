import { applyMiddleware, createStore, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';

import reducer from 'reducers/indexPwa.js';
import rootSaga from 'sagas/indexPwa.js';

const sagaMiddleware = createSagaMiddleware();

const middleware = applyMiddleware(createLogger(), sagaMiddleware);

const currentReducer = combineReducers({
  config: reducer
});
const store = createStore(currentReducer, middleware);

sagaMiddleware.run(rootSaga);

console.log("storePwa Staeted")
export default store; 
