import React, { useState, useEffect } from 'react';
import Icon from 'components/common/Icon';
import Button from 'components/common/Button';
import { __ } from 'utils/translation';
import CatalogItemSettings from 'components/CatalogItems/CatalogItemSettings';
import Slider from 'components/common/Slider';
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';
import menuOnline from 'images/menu-online.jpg';
import './index.scss';
import 'react-lazy-load-image-component/src/effects/blur.css';

const ShowReview = ({
    data,
    catalogItems,
    scrollPosition
}) => {
    const [settingsOpened, setSettingsOpened] = useState(null);
    const [catalogItemData, setCatalogItemData] = useState({});
  
    useEffect(() => {
        if (catalogItems) {
            const currentCatalogItem = catalogItems.find((catalogItem) => catalogItem.guid === settingsOpened);
            const settings = currentCatalogItem || { };
            setCatalogItemData({ ...settings }); 
        }
      }, [settingsOpened, catalogItems]); 
    
    if (!data || data.length === 0) {
        return null;
    }

    const closeCatalogItemsSettings = () => {
        setSettingsOpened(null);
    };

    const onOpenCatalogItemSettings = (catalogItemId) => {
        setSettingsOpened(catalogItemId);
    };
    
    const handleClick = (review) => {
        if (review.catalogItemId && catalogItems) {
            onOpenCatalogItemSettings(review.catalogItemId)
        } else {
            window.open(review.url,'_blank');
        }
    }

    const templatedDate = "0000-01-01 00:00:00"
    const localDate = (date) => {
        if (!date) {
            return null;
        }
        if (date.length < templatedDate.length) {
            date += templatedDate.substr(date.length, templatedDate.length - date.length)
        } else {
            date = date.substr(0, templatedDate.length)
        }
        const isoDate = new Date(date);
        return isoDate.toLocaleDateString();
    }
    

    return (
        <LazyLoadComponent scrollPosition={scrollPosition} threshold={10}>
            <div className="show-review">
                <div className="show-review__header">{__("Отзывы")}</div>
                <div className="show-review-picker">
                    <div className="show-review-picker__empty">
                        {data.map(review => <div className="show-review-picker-item">
                            <div className="show-review-picker-item-flexbox">
                                <div className="show-review-picker-item-flexbox__box" onClick={() => handleClick(review)} style={{ backgroundImage: `URL(${menuOnline})` }}  />
                                <LazyLoadImage 
                                src={review.image } 
                                alt={"sweety menu online reviews"} 
                                effect="blur"
                                scrollPosition={scrollPosition} threshold={10} />
                                <Button onClick={() => handleClick(review)} className="show-review-picker-item-flexbox__title">{review.author}</Button>
                                {localDate(review.date) && <div className="show-review-picker-item-flexbox__icon-box" onClick={() => handleClick(review)}>{localDate(review.date)}</div>}
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>
            <Slider
                opened={settingsOpened}
                onClose={closeCatalogItemsSettings}
                onSubmit={closeCatalogItemsSettings}
                submitTitle={__("ЗАКРЫТЬ")?.toUpperCase()}
            >
                <CatalogItemSettings {...catalogItemData} />
            </Slider>
            </LazyLoadComponent>
    );
};

export default ShowReview;
